(function () {

    var inherit = prisma.inherit;
    var image_path = "/api/image/view/";

    var update_catalog_dependencies = function (context, catalogId, key) {
        context.catalogs = context.catalogs || {};
        context.catalogs[catalogId] = context.catalogs[catalogId] || [];
        for (var i = 0; i < context.catalogs[catalogId].length; i++) {
            var d = context.catalogs[catalogId][i];
            if (d.catalog["parent-id"] != null &&
                d.catalog["parent-id"] == catalogId)
                d.updateCatalogOptions(key);
        }
    };

    var get_catalog_data = function (context, catalogId, key, success, error) {
        if (catalogId != null) {
            context.api.get(context.api.getUrl() + "api/catalogs/data/" + catalogId, {
                    key: key
                },
                function (response) {
                    success(jsedn.parse(response));
                }, error, null);
        } else
            success(new Array());
    }

    var unkeywordize = function (o) {
        if (typeof (o) == "string" && o.substr(0, 1) == ":")
            return o.substr(1);
        if (typeof (o) !== "object")
            return o;
        if (o instanceof Date)
            return o;

        var newObj = (o instanceof Array) ? [] : {};
        for (var i in o) {
            if (o.hasOwnProperty(i)) {
                var k = i;
                if (typeof i == "string" && i.substr(0, 1) == ":")
                    k = i.substr(1);
                if (o[i] && typeof o[i] == "object")
                    newObj[k] = unkeywordize(o[i]);
                else
                    newObj[k] = o[i];
            }
        }
        return newObj;
    }
    prisma.unkeywordize = unkeywordize;

    function background_image_style(bki, urlbase) {
        var bk = "";
        var alignmentHorizontal = bki["alignment-horizontal"];
        var alignmentVertical = bki["alignment-vertical"];
        var alignmentCenter = bki["alignment-center"];
        bk += "background-image: url(" + urlbase + image_path + bki["file-id"] + ");"
        bk += "background-position: ";
        if (alignmentHorizontal && alignmentVertical) {
            bk += unkeywordize(alignmentHorizontal) + " " + unkeywordize(alignmentVertical);
        } else if (alignmentHorizontal && alignmentCenter) {
            bk += unkeywordize(alignmentHorizontal) + " center";
        } else if (alignmentHorizontal) {
            bk += unkeywordize(alignmentHorizontal) + " top";
        } else if (alignmentVertical && alignmentCenter) {
            bk += "center " + unkeywordize(alignmentVertical);
        } else if (alignmentVertical) {
            bk += "left " + unkeywordize(alignmentVertical);
        } else if (alignmentCenter) {
            bk += "center center";
        } else
            bk + "left top";
        bk += ";";
        bk += "background-repeat: ";
        var repeatX = bki["repeat-x"];
        var repeatY = bki["repeat-y"];

        if (repeatX && repeatY) {
            bk += "repeat";
        } else if (repeatX) {
            bk += "repeat-x";
        } else if (repeatY) {
            bk += "repeat-y";
        } else {
            bk += "no-repeat";
        }
        bk += ";";
        bk += "background-size:";
        var stretch = bki["stretch"];
        if (stretch == ":cover") {
            bk += "cover";
        } else
            bk += "initial";
        return bk;
    }

    function error_top() {
        this.node = document.createElement("div")
        this.node.className = "error align-top";
        var arrow = document.createElement("span")
        arrow.className = "arrow";
        this.error_text = document.createElement("p");
        this.node.appendChild(arrow);
        this.node.appendChild(this.error_text);
    }
    error_top.prototype.getNode = function () {
        return this.node;
    }
    error_top.prototype.set = function (t) {
        this.error_text.innerHTML = t;
    }
    error_top.prototype.clear = function () {
        this.error_text.innerHTML = "";
    }

    function error_bottom() {
        this.node = document.createElement("div")
        this.node.className = "errors-bottom";
        this.error_text = document.createElement("p");
        this.node.appendChild(this.error_text);
    }
    error_bottom.prototype.getNode = function () {
        return this.node;
    }
    error_bottom.prototype.set = function (t) {
        this.error_text.innerHTML = t;
    }
    error_bottom.prototype.clear = function () {
        this.error_text.innerHTML = "";
    }


    function widget(context, opts, childs) {
        this.enabled = true;
        this.visible = true;
        this.eventListeners = {};
        for (var k in opts) {
            if (opts.hasOwnProperty(k))
                this[k] = opts[k];
        }
        if (this.context_registration !== false) {
            context.controls = context.controls || [];
            context.controls.push(this);
        }

        this.childs = childs;
        this.context = context;
    }

    widget.prototype._T = function (sectionName, key) {
        var translations = this.context.view.funnel && this.context.view.funnel.triggeringParent.translations;
        if (translations) {
            var campaignTranslation = prisma._T(sectionName, key, translations);
            if (campaignTranslation != "") {
                return campaignTranslation;
            } else {
                // if no campaign override is found, use the default
                return prisma._T(sectionName, key);
            }
        }
        return prisma._T(sectionName, key);
    }
    widget.prototype.render_translations = function (template) { //_T(":section",":message")}}
        var self = this;
        var tr = /_T\s*\(\s*\"([^\"]+)\"\s*,\"([^\"]+)\"\)/g
        if (template.match(tr)) {
            return template.replace(tr,
                function (_, section, key) {
                    return self._T(section, key);
                });
        } else {
            return template;
        };
    }

    widget.prototype.setEnabled = function (value) {
        this.enabled = value;
        for (var i = 0; i < this.childs.length; i++)
            this.childs[i].setEnabled(value);
    }
    widget.prototype.setValue = function(value){

    }


    widget.prototype.setVisible = function (value) {
        this.visible = value;
        for (var i = 0; i < this.childs.length; i++)
            this.childs[i].setVisible(value);
        this.setVisibility(value);
    }
    widget.prototype.setEnabled = function (value){
        this.enabled = value;
        for (var i = 0; i < this.childs.length; i++)
            this.childs[i].setEnabled(value);

    }

    widget.prototype.destroy = function () {

    }

    widget.prototype.responsiveLayout = function () {
        if (this["layout-type"] == "fixed") {
            return false
        } else {
            if (this.parent)
                return this.parent.responsiveLayout();
            else
                return true;
        }

    }

    widget.prototype.setVisibility = function (value) {
        if (this.canBeHidden()) {
            if (this.domNode) {
                if (value) {
                    prisma.removeClass(this.domNode, "hidden");
                } else {
                    prisma.addClass(this.domNode, "hidden");
                }
            }
            this.visible = value;
        }
    }
    widget.prototype.canBeHidden = function () {
        if (this["can-be-hidden"] === false)
            return false;
        for (var i = 0; i < this.childs.length; i++) {
            if (!this.childs[i].canBeHidden()) {
                return false;
            }
        }
        return true;
    }

    widget.prototype.setEnabled = function (value) {
        if (this["can-be-disabled"] === false)
            return false;

        this.enabled = value;
        for (var i = 0; i < this.childs.length; i++)
            this.childs[i].setEnabled(value);


    }
    widget.prototype.setError = function (errText) {

    }
    widget.prototype.addListener = function (evtName, fn) {
        if (!this.eventListeners[evtName]) {
            this.eventListeners[evtName] = [];
        }
        this.eventListeners[evtName].push(fn);
    };
    widget.prototype.fireEvent = function (evtName) {
        var args = [];
        var listeners;
        var res;
        listeners = this.eventListeners[evtName];
        if (listeners) {
            for (var i = 1; i < arguments.length; i++) {
                args.push(arguments[i]);
            }

            for (var idx = 0; idx < listeners.length; idx++) {
                if (typeof (listeners[idx]) === "function") {
                    res = listeners[idx].apply(this, args);
                    if (res !== undefined && res !== true) {
                        return res;
                    }
                }
            }
        }
    }
    widget.prototype.fireEventAsync = function (evtName, resolve, reject) {
        var fargs = []
        for (var i = 0; i < arguments.length; i++)
            fargs.push(arguments[i]);

        var listeners;
        var res;
        listeners = this.eventListeners[evtName];
        if (listeners) {
            var l = listeners.slice();
            var next = function () {
                var lst = l.shift();
                if(lst){
                    var args = [];
                    args.push(function(){
                        next();
                    })
                    args.push(function (err) {
                        reject(err)
                    })
                    for (var i = 3; i < fargs.length; i++) {
                        args.push(fargs[i]);
                    }
                    lst.apply(self, args)
                } else
                    resolve();
            }
            next();
        } else
            resolve();
    }


    widget.prototype.runScripts = function (scriptsId, externalResolveIdentifier,done) {

        var errors = [false];
        var self = this;
        var showErrors = function () {
            for (var i = 0; i < errors.length; i++) {
                self.setError(errors[i]);
            }
        }
        //https://developer.mozilla.org/es/docs/Web/API/WindowBase64/Base64_codificando_y_decodificando
        var bencode = function (str) {
            return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
                return String.fromCharCode('0x' + p1);
            }));
        }
        var internalResolveIdentifier = function (identifier,resolve){

            switch (identifier) {
                case "rest":
                    resolve (function(resolve,method,url,headers,params){
                        var headersEdn = new jsedn.Map();
                        for (k in headers){
                            if(headers.hasOwnProperty(k)){
                                headersEdn.set(k,headers[k]);
                            }
                        }
                        var paramsEdn = new jsedn.Map();
                        for (k in params){
                            if(params.hasOwnProperty(k)){
                                paramsEdn.set(k,bencode(params[k]));
                            }
                        }


                        self.context.view.funnel.restRequest(new jsedn.Map([new jsedn.kw(":action"),new jsedn.kw (":rest-request"),
                                                               new jsedn.kw(":url"), url,
                                                               new jsedn.kw(":method"),method,
                                                               new jsedn.kw(":headers"), headersEdn,
                                                               new jsedn.kw(":params") ,paramsEdn])
                                                ,function(data){
                                                    resolve(data)
                                                },
                                                function (error){
                                                    resolve({status: false,
                                                             error: error})
                                                });
                    });
                break;
                case "this":
                    resolve(self);
                    break;
                case "addValidations":
                    resolve(function (resolve, cbcall) {
                        if (typeof cbcall == "function") {
                            self.addListener("validate-async", function (resolve, reject, value) {
                                var r = null;
                                cbcall(
                                    function (value) {
                                        if (!value || value == true) {
                                            resolve()
                                        } else if (typeof value == "string") {
                                            reject(value)
                                        } else if (r)
                                            reject(r)
                                        else
                                            resolve();
                                    },
                                    {fn: function (err){
                                        reject(err)
                                    }},
                                    value
                                );
                            })
                        }
                        resolve();
                    })
                    break;
                case "event:change":
                    resolve(function (resolve,cbcall){
                        if(typeof cbcall == "function"){
                            self.addListener("onChange", function (value){
                                if(value.field && value.value){ // datacapture onChange ???
                                    cbcall(function (){},
                                           value.field["name"],
                                           value.value);

                                }else {
                                    cbcall(function (){},
                                    value);
                                }
                            });
                        }
                        resolve();
                    })
                    break;
                case "setError":
                    resolve(function (resolve, message) {
                        resolve(errors.push(message));
                    })
                    break;
                case "contains":
                    resolve(function (resolve, a, b) {
                        resolve(('' + a).toLowerCase().indexOf(('' + b).toLowerCase()) !== -1)
                    })
                    break;
                case "show":
                    resolve(function (resolve, controlId) {
                        resolve(self.context.view.showControl(controlId));
                    })
                    break;
                case "hide":
                    resolve(function (resolve, controlId) {
                        resolve(self.context.view.hideControl(controlId));
                    })
                    break;
                case "setFieldValue":
                    resolve (
                        function (resolve,fieldName,newValue){
                            var field = null;
                            for (var i = 0;!field && i< self.context.controls.length;i++){
                                var c = self.context.controls[i];
                                if (c["fields-data"]){
                                    for (var j=0;!field && j<c.inputs.length;j++){
                                        var di = c.inputs[j];
                                        if(di.field["name"] == fieldName){
                                            field = di.field
                                            di.input.setValue(newValue);
                                        }
                                    }
                                }
                            }
                            field = field || {"field-name": ":"+fieldName};
                            self.context.view.handleFieldChange(field,newValue);
                        resolve()})
                break;
                case "enable":
                    resolve(function (resolve, controlId) {
                        resolve(self.context.view.enableControl(controlId));
                    })
                    break;
                case "disable":
                    resolve(function (resolve, controlId) {
                        resolve(self.context.view.disableControl(controlId));
                    });
                    break;
                case "resolve":
                    resolve(function (resolve, symbol) {
                        var fname;
                        var value;
                        if (symbol[0] == ":")
                            fname = symbol.substr(1);
                        else
                            fname = symbol;
                        var i = 0;
                        while ((value == undefined || value == null) && i < self.context.controls.length) {
                            var control = self.context.controls[i];
                            if (control["fields-data"]) {
                                value = control["fields-data"][fname]
                            }
                            i++;
                        }
                        if (symbol[0] == '"' && symbol[symbol.length - 1] == '"') {
                            symbol = symbol.substr(1, symbol.length - 1);
                        }
                        resolve(value !== undefined ? value : symbol);
                    });
                    break;
                case "date:now":
                    resolve(function (resolve) {
                        resolve(new Date());
                    });
                break;
                case "date:year":
                    resolve(function (resolve,d) {
                        resolve(d.getFullYear());
                    });
                break;
                case "date:UTCyear":
                    resolve(function (resolve,d) {
                        resolve(d.getUTCFullYear());
                    });
                break;
                case "date:month":
                    resolve(function (resolve,d) {
                        resolve(d.getMonth()+1);
                    });
                break;
                case "date:UTCmonth":
                    resolve(function (resolve,d) {
                        resolve(d.getUTCMonth()+1);
                    });
                break;
                case "date:day":
                    resolve(function (resolve,d) {
                        resolve(d.getDate());
                    });
                break;
                case "date:UTCday":
                    resolve(function (resolve,d) {
                        resolve(d.getUTCDate());
                    });
                break;
                case "date:hour":
                    resolve(function (resolve,d) {
                        resolve(d.getHours())
                    });
                break;
                case "date:UTChour":
                    resolve(function (resolve,d) {
                        resolve(d.getUTCHours());
                    });
                break;
                case "date:minutes":
                    resolve(function (resolve,d) {
                        resolve(d.getMinutes());
                    });
                break;
                case "date:seconds":
                    resolve(function (resolve,d) {
                        resolve(d.getSeconds());
                    });
                break;
                case "json:parse":
                    resolve (function (resolve,s){
                        resolve(JSON.parse(s))
                    });
                break;
                case "json:stringify":
                    resolve (function (resolve,s){
                        resolve(JSON.stringify(s))
                    });
                break;
                case "string:ucase":
                    resolve (function (resolve,s){
                        resolve(s.toUpperCase())
                    });
                break;
                case "string:lcase":
                    resolve (function (resolve,s){
                        resolve(s.toLowerCase())
                    });
                break;
                case "field":
                    resolve(function (resolve, controlId) {
                        var fname;
                        var value;
                        if (controlId[0] == ":")
                            fname = controlId.substr(1);
                        else
                            fname = controlId;
                        var i = 0;
                        while ((value == undefined || value == null) && i < self.context.controls.length) {
                            var control = self.context.controls[i];
                            if (control["fields-data"]) {
                                value = control["fields-data"][fname]
                            }
                            i++;
                        }
                        resolve(value);
                    });
                    break;
                default:
                    resolve(null);
            }
        }
        var resolveIdentifier = function (identifier, resolve) {
            if (externalResolveIdentifier) {
                externalResolveIdentifier(identifier, function (value) {
                    if (value == null) {
                        internalResolveIdentifier(identifier, resolve)
                    } else {
                        resolve(value);
                    }

                })
            } else
                internalResolveIdentifier(identifier, resolve);
        }

        var c = this.childs.slice();
        var next = function () {
            var cl = c.shift();
            if (cl) {
                cl.runScripts(scriptsId, null,function () {
                    next()
                });

            } else {
                if (self[scriptsId] && self[scriptsId].length > 0) {
                    var baseUrl = self.context.api.protocol + "//" + self.context.api.server + ":" + self.context.api.port + "/";
                    prisma.loadScript(baseUrl + "sdk/javascript/clss.js",
                        "script",
                        function (status) {
                            if (status) {
                                prisma.script.evaluate(self[scriptsId],
                                    function (result) {
                                        showErrors();
                                        done();
                                    }, //done
                                    function (error) { //error
                                        console.log(error);
                                        done();
                                    },
                                    function (identifier, done) {
                                        resolveIdentifier(identifier, done)
                                    });
                            } else
                                done();
                        }
                    );
                } else
                    done();
            }

        }
        next();
    }

    widget.prototype.validate = function (value, resolve, reject) {
        var self = this;
        var fireValidationScripts = function () {
            self.fireEventAsync("validate-async",
                function () {
                    var s = self.fireEvent("validate", value);
                    if (s && s !== true) {
                        reject(s)
                    } else {
                        var field = (self.field || { name: "" })
                        self.context.view.fireFieldValidateEvents(
                            field["ns:name"] || field["name"],
                            resolve,
                            reject,
                            value);
                    }

                },
                function (err) {
                    reject(err)
                }, value)
        }
        if (value == null || value === '') {
            if (self.required && self.visible) {
                reject(self._T(":funnel-navigation", ":errors-fields-required"));
            } else
                fireValidationScripts();
        } else {
            fireValidationScripts();
        }
    }

    prisma.widget = widget;

    function container(context, opts, childs) {
        container.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(container, widget);
    container.prototype.create = function (elementClass) {
        var n = document.createElement(elementClass?elementClass:"div");
        n.id = this.id;
        for (var i = 0; i < this.childs.length; i++) {
            if (this.childs[i] == undefined) {
                continue;
            }
            this.childs[i].parent = this;
            n.appendChild(this.childs[i].create());
        }
        this.domNode = n;
        return n;
    }
    container.prototype.destroy = function () {
        for (var i = 0; i < this.childs.length; i++) {
            this.childs[i].destroy();
        }
    }

    function panel(context, opts, childs) {
        panel.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(panel, container);

    panel.prototype.create = function () {
        var panelContent = panel.SUPERclass.create.call(this);
        var panelComponent = document.createElement("div");
        panelComponent.className = "prisma-panel-component";
        panelComponent.id = this.id;
        var componentStyle = (this.margin ? "margin: " + this.margin + ";" : "");
        panelComponent.setAttribute("style", componentStyle);
        //header
        var panelHeader = document.createElement("div");
        if (this.header) {
            panelHeader.className = "prisma-panel-heading";
            var panelHeaderText = document.createElement("p");
            var fontStyle = (this.font ? (this.font["font-family"] ? "font-family:" + this.font["font-family"] + ";" : "") +
                (this.font["font-style"] ? "font-style:" + this.font["font-style"] + ";" : "") +
                (this.font["font-size"] ? "font-size:" + this.font["font-size"] + "px;" : "") +
                (this.font["font-weight"] ? "font-weight:" + this.font["font-weight"] + ";" : "") : "");
            panelHeaderText.setAttribute("style", fontStyle);
            panelHeaderText.innerHTML = this.title;
            panelHeader.appendChild(panelHeaderText);

        }

        // content
        var panelBody = document.createElement("div");
        if (this.header) {
            panelBody.className = "prisma-panel-body";
        }
        var contentStyle = (this.padding ? "padding: " + this.padding + ";" : "") +
            (this.background ? "background: " + this.background + ";" : "") +
            (this.border ? "border: " + this.border.width + " solid " + (this.border.color || "black") + ";" : "");
        panelBody.setAttribute("style", contentStyle);
        panelBody.appendChild(panelContent);
        panelComponent.appendChild(panelHeader);
        panelComponent.appendChild(panelBody);
        this.domNode = panelComponent;
        return panelComponent;
    }

    prisma.panel = panel;

    function tabs(context, opts, childs) {
        tabs.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(tabs, widget);

    tabs.prototype.destroy = function () {
        for (var i = 0; i < this.childs.length; i++) {
            this.childs[i].destroy();
        }
    }

    tabs.prototype.create = function () {
        var self = this;
        var tabBar = document.createElement("div");
        var alignment = this["tab-alignment"] || "top";
        var tabStyle = this["style"] || "classic";
        tabBar.className = "prisma-tab-component " + "prisma-tab-align-" + alignment + " " + tabStyle;
        tabBar.id = this.id;

        var tabBarStyle = (this.margin ? "margin: " + this.margin + ";" : "");
        tabBar.setAttribute("style", tabBarStyle);

        var tabBarNav = document.createElement("ul");
        tabBarNav.className = "prisma-tab-nav";

        var tabBarContent = document.createElement("div");
        tabBarContent.className = "prisma-tab-content";

        var contentStyle = (this.padding ? "padding: " + this.padding + ";" : "") +
            (this.background ? "background: " + this.background + ";" : "");
        tabBarContent.setAttribute("style", contentStyle);

        var fontStyle = (this.font ? (this.font["font-family"] ? "font-family:" + this.font["font-family"] + ";" : "") +
            (this.font["font-style"] ? "font-style:" + this.font["font-style"] + ";" : "") +
            (this.font["font-size"] ? "font-size:" + this.font["font-size"] + "px;" : "") +
            (this.font["font-weight"] ? "font-weight:" + this.font["font-weight"] + ";" : "") : "");
        var fontColor = (this.font ? (this.font["color"] ? "color:" + this.font["color"] + ";" : "") : "");

        var itemStyle = (self['accent-color'] ? "color: " + self['accent-color'] + ";" : "") +
            (self.background ? "background: " + self.background + ";" : "") +
            (self['accent-color'] ? "border-color: " + (self['accent-color'] || "black") + ";" : "") +
            fontStyle;

        //all tab hiding and showing is done using classes against the
        //navigator and content panels, which are always created but not visible
        var selectItem = function (idx) {
            for (var i = 0; i < tabBarNav.children.length; i++) {
                if (i == idx) {
                    prisma.addClass(tabBarNav.children[i], "active");
                    tabBarNav.children[i].children[0].setAttribute("style", itemStyle);
                } else {
                    prisma.removeClass(tabBarNav.children[i], "active");
                    tabBarNav.children[i].children[0].setAttribute("style", fontStyle + ";" + fontColor);
                }
            }
            for (var i = 0; i < tabBarContent.children.length; i++) {
                if (i == idx) {
                    prisma.addClass(tabBarContent.children[i], "active");
                } else {
                    prisma.removeClass(tabBarContent.children[i], "active");
                }
            }
        }
        for (var i = 0; i < this.childs.length; i++) {
            var tabBarItem = document.createElement("li");
            var tabBarItem_link = document.createElement("a");
            if (0 == i) {
                tabBarItem.className = "active";
                tabBarItem_link.setAttribute("style", itemStyle);
            } else {
                tabBarItem_link.setAttribute("style", fontStyle + ";" + fontColor);
            }

            tabBarItem_link.onclick = (function (idx) {
                return function (e) {
                    selectItem(idx)
                }
            })(i);
            tabBarItem_link.innerHTML = this.childs[i].title;



            tabBarItem.appendChild(tabBarItem_link);
            tabBarNav.appendChild(tabBarItem);
            var itemContent = document.createElement("div");
            itemContent.className = "prisma-tab-pane " + ((0 == i) ? "active" : "");
            itemContent.appendChild(this.childs[i].create());
            tabBarContent.appendChild(itemContent);
        }
        if (alignment == "bottom") {
            tabBar.appendChild(tabBarContent);
            tabBar.appendChild(tabBarNav);
        } else {
            tabBar.appendChild(tabBarNav);
            tabBar.appendChild(tabBarContent);
        }
        this.domNode = tabBar;
        return tabBar;
    }

    prisma.tabs = tabs;


    function tab(context, opts, childs) {
        tabs.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(tab, container);

    tab.prototype.create = function () {
        //this calls the container.create function which iterates over all childs
        //and creates them as subnodes of this node
        var c = tab.SUPERclass.create.call(this);
        return c;
    }

    prisma.tab = tab;

    function accordion(context, opts, childs) {
        accordion.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(accordion, widget);

    accordion.prototype.destroy = function () {
        for (var i = 0; i < this.childs.length; i++) {
            this.childs[i].destroy();
        }
    }

    accordion.prototype.create = function () {
        var self = this;
        var accordion = document.createElement("div");
        accordion.className = "prisma-accordion-component";
        accordion.id = this.id;
        var accordionBarStyle = (this.margin ? "margin: " + this.margin + ";" : "");
        accordion.setAttribute("style", accordionBarStyle);

        var contentStyle = (this.padding ? "padding: " + this.padding + ";" : "") +
            (this.background ? "background: " + this.background + ";" : "") +
            (this.border ? "border: " + this.border.width + " solid " + (this.border.color || "black") + ";" : "");

        var fontStyle = (this.font ? (this.font["font-family"] ? "font-family:" + this.font["font-family"] + ";" : "") +
            (this.font["font-style"] ? "font-style:" + this.font["font-style"] + ";" : "") +
            (this.font["font-size"] ? "font-size:" + this.font["font-size"] + "px;" : "") +
            (this.font["font-weight"] ? "font-weight:" + this.font["font-weight"] + ";" : "") : "");
        var fontColor = (this.font ? (this.font["color"] ? "color:" + this.font["color"] + ";" : "") : "");


        var itemStyle = (self['accent-color'] ? "color: " + self['accent-color'] + ";" : "") + fontStyle;

        //item selection should change caret in all non selected items
        var selectItem = function (idx) {
            for (var i = 0; i < accordion.children.length; i++) {
                if (i == idx) {
                    prisma.addClass(accordion.children[i], "active");
                    accordion.children[i].children[0].setAttribute("style", itemStyle);
                    accordion.children[i].children[1].setAttribute("style", contentStyle);
                    var caret = accordion.children[i].getElementsByClassName("caret-right")[0];
                    if (caret) {
                        caret.className = "caret";
                    }
                } else {
                    prisma.removeClass(accordion.children[i], "active");
                    accordion.children[i].children[0].setAttribute("style", fontStyle + ";" + fontColor);
                    accordion.children[i].children[1].setAttribute("style", '');
                    var caret = accordion.children[i].getElementsByClassName("caret")[0];
                    if (caret) {
                        caret.className = "caret-right";
                    }
                }
            }
        }

        for (var i = 0; i < this.childs.length; i++) {
            var accItem = document.createElement("div");
            var accItem_link = document.createElement("a");
            var itemContent = document.createElement("div");
            if (0 == i) {
                accItem.className = "active";
                itemContent.setAttribute("style", contentStyle);
                accItem_link.setAttribute("style", itemStyle);
            } else {
                accItem_link.setAttribute("style", fontStyle + ";" + fontColor);
            }

            var accItem_caret = document.createElement("span");
            accItem_caret.className = (0 == i) ? "caret" : "caret-right";
            accItem_link.onclick = (function (idx) {
                return function (e) {
                    selectItem(idx)
                }
            })(i);
            var accItem_name = document.createElement("span");
            accItem_name.innerHTML = this.childs[i].title;
            accItem_link.appendChild(accItem_caret);
            accItem_link.appendChild(accItem_name);

            itemContent.className = "prisma-accordion-content";
            itemContent.appendChild(this.childs[i].create());
            accItem.appendChild(accItem_link);
            accItem.appendChild(itemContent);
            accordion.appendChild(accItem);
        }
        this.domNode = accordion;
        return accordion;
    }

    prisma.accordion = accordion;

    function item(context, opts, childs) {
        accordion.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(item, container);

    item.prototype.create = function () {
        //this calls the container.create function which iterates over all childs
        //and creates them as subnodes of this node
        var c = item.SUPERclass.create.call(this);
        return c;
    }

    prisma.item = item;
    function hstack(context, opts, childs) {
        hstack.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(hstack, container);

    hstack.prototype.create = function () {
        for (var i = 0; i < this.childs.length - 1; i++) {
            this.childs[i].spacing = this.spacing;
            this.childs[i].valign = this.valign;
        }

        var n = hstack.SUPERclass.create.call(this, "tr");
        var tableWrapper = document.createElement("div");
        var table = document.createElement("table");
        tableWrapper.id = this.id;
        // reset tr id created by the supperclass
        n.removeAttribute('id');
        tableWrapper.className = (this.align && this.align != "" ? "text-" + this.align : "");
        table.className = "prisma-stack-component";
        var s = (this.padding ? "padding: " + this.padding + ";" : "") +
            (this.border ? "border: " + this.border.width + " solid " + (this.border.color || "black") + ";" : "") +
            (this.background ? "background: " + this.background + ";" : "");
        table.setAttribute("style", s);

        table.appendChild(n);
        tableWrapper.appendChild(table);
        this.domNode = tableWrapper;
        return tableWrapper;
    }
    prisma.hstack = hstack;

    function cell(context, opts, childs) {
        cell.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(cell, container);

    cell.prototype.create = function () {
        var n = cell.SUPERclass.create.call(this, "td");
        n.className = "prisma-cell-component text-left";

        var ns = (this.valign ?  this.valign : "middle")
        n.setAttribute("valign", ns);

        var s = (this.width ? "width: " + this.width + ";" : "") +
            (this.spacing ? "padding-right:" + this.spacing : "");
        n.setAttribute("style", s);
        return n;
    }
    prisma.cell = cell;

    function root(context, opts, childs) {
        root.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(root, container);
    root.prototype.create = function () {
        var urlbase = this.context.api.protocol + "//" + this.context.api.server + ":" + this.context.api.port;
        var c = root.SUPERclass.create.call(this);
        c.className = "prisma-root-component";


        var align = "margin-left:auto;margin-right:auto";

        switch (this.align) {
            case "left":
                align = "margin-left:0;margin-right:auto;";
                break;
            case "center":
                align = "margin-left:auto;margin-right:auto";
                break;

            case "right":
                align = "margin-left:auto;margin-right:0";
                break;
        }

        var s = "padding:" + (this["content-padding"] || "0px") + ";" +
            (this["content-background-color"] ? "background: " + this["content-background-color"] + ";" : "") +
            (this.border ? "border: " + this.border.width + " solid " + (this.border.color || "black") + ";" : "") +
            (this.width && this.context.resolution == "default" ? "max-width: " + this.width + ";" : "") +
            align;

        c.setAttribute("style", s);
        var s = "";
        var n = document.createElement("div");
        n.id = "root-wrapper";
        n.className = "root-wrapper";
        var bk = "";
        if (this["background-image"]) {
            bk = background_image_style(this["background-image"], urlbase);
        };

        s = (this.padding ? "padding: " + this.padding + ";" : "") +
        (this["background-color"] ? "background: " + this["background-color"] + ";" : "") +
            bk;

        n.setAttribute("style", s);
        n.appendChild(c);
        return n;
    }

    prisma.root = root;

    function col(context, opts, childs) {
        col.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(col, container);

    col.prototype.create = function () {
        var urlbase = this.context.api.protocol + "//" + this.context.api.server + ":" + this.context.api.port;
        var n = col.SUPERclass.create.call(this);
        var cs = "md";
        if (this.parent.responsiveLayout()) {
            switch (this.context.resolution) {
                case "default":
                    cs = "md";
                    break;
                case "medium":
                    cs = "sm";
                    break;
                case "small":
                    cs = "xs";
                    break;
            }
        } else {
            cs = "xs"
        }
        n.className = "prisma-col-component col-" + cs + "-" + this.size + " " + (this.class || "");
        var bk = "";
        if (this["background-image"]) {
            bk = background_image_style(this["background-image"], urlbase);
        };

        var s = (this.padding ? "padding: " + this.padding + ";" : "") +
            (this.border ? "border: " + this.border.width + " solid " + (this.border.color || "black") + ";" : "") +
            (this.height ? "height:" + this.height + ";" : "") +
            (this["background-color"] ? "background: " + this["background-color"] + ";" : "") +
            (this.align ? "text-align:" + this.align + ";" : "") +
            bk;
        n.setAttribute("style", s);
        return n;
    };
    prisma.col = col;

    function row(context, opts, childs) {
        row.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(row, container);
    row.prototype.create = function () {
        var urlbase = this.context.api.protocol + "//" + this.context.api.server + ":" + this.context.api.port;
        var n = row.SUPERclass.create.call(this);
        n.className = "row prisma-row-component" + " " + (this.class || "");
        var bk = "";
        if (this["background-image"]) {
            bk = background_image_style(this["background-image"], urlbase);
        };

        var s = (this.padding ? "padding: " + this.padding + ";" : "") +
            (this.border ? "border: " + this.border.width + " solid " + (this.border.color || "black") + ";" : "") +
            (this["background-color"] ? "background: " + this["background-color"] + ";" : "") +
            (this.align ? "text-align:" + this.align + ";" : "") +
            (this["max-width"] ? "max-width:"+ this["max-width"]+";":"") +
            (this["text-align"] ? "text-align:"+ this["text-align"]+";":"") +
            (this["margin"] ? "margin:"+ this["margin"]+";":"")+
            bk;
        n.setAttribute("style", s);
        return n;
    };
    prisma.row = row;

    function image(context, opts, childs) {
        image.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(image, widget);

    image.prototype.create = function () {
        var urlbase = this.context.api.protocol + "//" + this.context.api.server + ":" + this.context.api.port
        var n = document.createElement("div");
        n.className = this.align && this.align != ""?"text-"+this.align:"";
        n.id = this.id;
        var imageNode = document.createElement("img");
        var width = "auto";
        var height = "auto";
        if (this.size && this.size.width)
            width = this.size.width;
        if (this.size && this.size.height)
            height = this.size.height;

        var s = "width:" + width + ";" +
            "height:" + height + ";" +
            (this.border ? "border: " + this.border.width + " solid " + (this.border.color || "black") + ";" : "") +
            (this.padding ? "padding: " + this.padding + ";" : "") +
            (this.opacity ? "opacity: " + (this.opacity / 100) + ";" +
                "-ms-filter: \"alpha(opacity=" + this.opacity + ")\";" +
                "filter: alpha(opacity=" + this.opacity + ");" +
                "-khtml-opacity: " + (this.opacity / 100) + ";" +
                "-moz-opacity: " + (this.opacity / 100) + ";" : "") +
            (this.background ? "background: " + this.background + ";" : "") +
            "max-width: 100%;";
        if (this.image) {
            if (this.image.url) {
                if (this.image.url.indexOf("http") == 0){
                    imageNode.setAttribute("src", this.image.url);
                }else {
                    imageNode.setAttribute("src", urlbase + this.image.url);
                }
            } else {
                imageNode.setAttribute("src", urlbase + image_path + this.image["file-id"]);
            }
        }

        imageNode.setAttribute("style", s);
        if (this.alt) {
            imageNode.setAttribute("alt", this.alt);
        }
        this.domNode = n;
        if (this.link) {
            var a = document.createElement("a");
            a.setAttribute("href", this.link);
            a.appendChild(imageNode);
            n.appendChild(a);
        } else {
            n.appendChild(imageNode);
        }
        return n;

    }
    prisma.image = image;

    function form_widget(context, opts, childs) {
        form_widget.SUPERconstructor.call(this, context, opts, childs);
    }

    inherit(form_widget, widget);

    form_widget.prototype.vertical_layout = function (controlNode, opts) {
        opts = opts || {}
        var frmgroup = document.createElement("div");
        frmgroup.className = "form-group";
        this.frmgroup = frmgroup;
        var label = document.createElement("label");
        label.className = "control-label " + (this.required ? "required" : "");
        label.innerText = this.label || "";

        this.error_indicator = new error_top();

        if (opts.mode === "checkbox") {
            label.innerText = "";
            label.appendChild(controlNode);

            if (this.field && this.field['switch-style']) {
                var switchSlider = document.createElement("span");
                switchSlider.className = "prisma-switch-slider";
                label.appendChild(switchSlider);
            }

            var spanText = document.createElement("span");
            spanText.className = "prisma-checkbox-text";
            spanText.innerText = this.label;
            label.appendChild(spanText);
            frmgroup.appendChild(label);
        } else {
            frmgroup.appendChild(label);
            frmgroup.appendChild(this.error_indicator.getNode());
            frmgroup.appendChild(controlNode);
        }
        this.label = label;
        return frmgroup;
    }

    form_widget.prototype.horizontal_6_6_layout = function (controlNode, grid_size, opts) {
        var frmgroup = document.createElement("div");
        frmgroup.className = "form-group";
        this.frmgroup = frmgroup;
        var label = document.createElement("label");
        var row = document.createElement("div")
        row.className = "row";
        label.className = "control-label " + (this.required ? "required" : "");
        label.innerText = this.label || "";
        this.label = label;
        this.error_indicator = new error_top();
        var col1 = document.createElement("div");
        col1.className = "col-" + grid_size + "-6";
        var col2 = document.createElement("div");
        col2.className = "col-" + grid_size + "-6";
        col1.appendChild(label);
        col2.appendChild(this.error_indicator.getNode());
        col2.appendChild(controlNode);

        row.appendChild(col1);
        row.appendChild(col2);
        frmgroup.appendChild(row);
        return frmgroup;
    }

    form_widget.prototype.horizontal_3_9_layout = function (controlNode, grid_size, opts) {
        var frmgroup = document.createElement("div");
        frmgroup.className = "form-group";
        this.frmgroup = frmgroup;
        var label = document.createElement("label");
        var row = document.createElement("div")
        row.className = "row";
        label.className = "control-label " + (this.required ? "required" : "");
        label.innerText = this.label || "";
        this.label = label;
        this.error_indicator = new error_top();
        var col1 = document.createElement("div");
        col1.className = "col-" + grid_size + "-3";
        var col2 = document.createElement("div");
        col2.className = "col-" + grid_size + "-9";
        col1.appendChild(label);
        col2.appendChild(this.error_indicator.getNode());
        col2.appendChild(controlNode);

        row.appendChild(col1);
        row.appendChild(col2);
        frmgroup.appendChild(row);
        return frmgroup;
    }

    form_widget.prototype.form_layout = function (controlNode, opts) {
        var grid_size = "md";
        if (this.responsiveLayout()) {
            switch (this.context.resolution) {
                case "default":
                    grid_size = "md";
                    break;
                case "medium":
                    grid_size = "sm";
                    break;
                case "small":
                    grid_size = "xs";
                    break;
            }
        } else {
            grid_size = "xs";
        }

        switch (this.form_alignment) {
            case "vertical":
                return this.vertical_layout(controlNode, opts);
            case "horizontal-6-6":
                return this.horizontal_6_6_layout(controlNode, grid_size, opts);
            case "horizontal-3-9":
                return this.horizontal_3_9_layout(controlNode, grid_size, opts);
            default:
                return this.vertical_layout(controlNode, opts);

        }
    }
    form_widget.prototype.displayError = function (errorText) {
        if (errorText) {
            prisma.addClass(this.frmgroup, "has-error");
            this.error_indicator.set(errorText);

        } else {
            prisma.removeClass(this.frmgroup, "has-error");
            this.error_indicator.clear();
        }

    }

    /** @constructor */
    function text(context, opts, childs) {
        text.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(text, widget);

    text.prototype.create = function () {

        var n = document.createElement("div");
        n.id = this.id;
        var s = (this.padding ? "padding: " + this.padding + ";" : "") +
                "line-height:" + (this["line-height"] || "1.42em") + ";";
        n.setAttribute("style", s);
        n.className = "prisma-text-component text-left";
        n.innerHTML = this.render_translations(this.text || "");
        this.domNode = n;
        return n;
    }
    prisma.text = text;

    function combobox(context, opts, childs) {
        combobox.SUPERconstructor.call(this, context, opts, childs);
        this.system_enabled = true;
        this.user_enabled = true;

    }
    inherit(combobox, form_widget);

    combobox.prototype.updateCatalogOptions = function (key) {
        var self = this;
        for (var i = this.select.options.length - 1; i >= 0; i--) {
            this.select.remove(i);
        }
        if (key !== null && key !== '' && key !== undefined) {
            get_catalog_data(this.context, this.catalog.id, key,
                function (response) {
                    var l = response.val.length
                    self.buildOptions(response.val);
                    self.setEnabled(l > 0 && self.user_enabled, true);
                    update_catalog_dependencies(self.context, self.catalog.id, self.value);
                },
                function () {});
        } else {
            self.value = null;
            self.fireEvent("onChange",null)
            update_catalog_dependencies(self.context, self.catalog.id, null);
        }
    }
    combobox.prototype.buildOptions = function (data) {
        // Dummy entry, hidden and disabled to start combobox unselected
        var value = this.value;
        if (this.storage_key &&
            this.context.control_storage &&
            this.context.control_storage[this.storage_key])
            value = this.context.control_storage[this.storage_key];
        var option = document.createElement("option");
        option.selected = true;
        option.disabled = true;
        option.text = "";
        option.style = "display:none";
        this.select.add(option);
        var match = null;
        for (var i in data) {
            if (data.hasOwnProperty(i)) {
                var option = document.createElement("option");
                if(data[i].text !== undefined && data[i].value !== undefined ){
                    option.text = data[i].text;
                    option.value = data[i].value;
                }else if (data[i].val && data[i].val[0] !== undefined && data[i].val[1] !== undefined){
                    option.text = data[i].val[1];
                    option.value = data[i].val[0];
                } else {
                    option.text = data[i];
                }
                if (prisma.valueToString(option.value) == prisma.valueToString(value)) {
                    option.selected = true;
                    match = this.value;
                }else if (prisma.valueToNumber(option.value) == prisma.valueToNumber(value)) {
                    option.selected = true;
                    match = option.value;
                }
                this.select.add(option);
            }
        }
        if (this.value !== match) {
            this.fireEvent("onChange",match)
        }
        this.value = match;
    }

    combobox.prototype.updateOptions = function (options) {
        for (var i = this.select.options.length - 1; i >= 0; i--) {
            this.select.remove(i);
        }
        this.buildOptions(options);
        this.select.selectedIndex = "-1";
    };
    combobox.prototype.setValue = function (newValue){
        if(this.select)
            this.select.value = newValue;
    }
    combobox.prototype.create = function () {
        var self = this;
        self.user_enabled = self.enabled;
        this.select = document.createElement("select");
        this.select.className = "form-control";
        this.select.id = this.id;
        this.select.ariaLabel = this.label;
        var value = self.value;
        if (this.storage_key &&
            this.context.control_storage &&
            this.context.control_storage[this.storage_key])
            value = this.context.control_storage[this.storage_key];

        if (this.srctype == "catalog") {
            if (this.catalog && this.catalog.id) {
                this.context.catalogs = this.context.catalogs || {};
                this.context.catalogs[this.catalog.id] = this.context.catalogs[this.catalog.id] || [];
                this.context.catalogs[this.catalog.id].push(this);
                if (!this.catalog["parent-id"]) {
                    get_catalog_data(this.context, this.catalog.id, null,
                        function (response) {
                            var l = response.val.length;
                            self.buildOptions(response.val);
                            self.setEnabled(l > 0 && self.user_enabled, true);

                            update_catalog_dependencies(self.context, self.catalog.id, self.value);
                        },
                        function () {})
                } else {
                    this.context.catalogs[this.catalog["parent-id"]] = this.context.catalogs[this.catalog["parent-id"]] || [];
                    this.context.catalogs[this.catalog["parent-id"]].push(this);
                }
            }
        } else {
            this.buildOptions(this.options);
        }
        prisma.setEvent(this.select, "change",
            function (selectObj) {
                self.value = self.select.value;

                if (self.srctype == "catalog")
                    update_catalog_dependencies(self.context, self.catalog.id, self.value);
                self.fireEvent("onChange",self.value);
            }
        );
        var frmgroup = this.form_layout(this.select);
        if(!(this.catalog && this.catalog.id && !this.catalog["parent-id"] && this.srctype == "catalog")){
            this.setEnabled(this.options &&
            this.options.length > 0 && this.enabled, true);
        }
        this.domNode = frmgroup;

        return frmgroup;
    }
    combobox.prototype.setEnabled = function (newVal, internalCall) {

        if (internalCall) {
            this.system_enabled = newVal;
            if (newVal == true && this.user_enabled == false)
                return;
        } else {
            this.user_enabled = newVal;
            if(this.system_enabled == false && newVal == true)
                return;
        }

        combobox.SUPERclass.setEnabled.call(this, newVal);
        this.select.disabled = !this.enabled;
        if (this.enabled) {
            prisma.removeClass(this.label, "disabled");
        } else {
            prisma.addClass(this.label, "disabled");
        }
    }
    combobox.prototype.retrieveData = function (cb) {
        var self = this;
        var value = this.select.value;
        if ((value == null || value == "") && this.required && this.visible) {
            prisma.addClass(this.frmgroup, "has-error");
            this.displayError(this._T(":funnel-navigation", ":errors-option-required"));
            cb(false);
        } else {
            this.validate(value,function(){
                self.displayError(null);
                cb(true, value);
            },function(err){
                self.displayError(err);
                cb(false)
            })
        }
    }
    combobox.prototype.destroy = function () {
        if (this.storage_key) {
            this.context.control_storage = this.context.control_storage || {};
            this.context.control_storage[this.storage_key] = this.select.value;
        }
    }
    combobox.prototype.onChange = function (fn){
        this.addListener("onChange",fn);
    }
    prisma.combobox = combobox;


    function MultioptionBase(context, opts, childs) {
        MultioptionBase.SUPERconstructor.call(this, context, opts, childs);
        this.inputs = [];
        this.system_enabled = true;
        this.user_enabled = true;
        this.cardinality = this.cardinality || "one";
    }
    inherit(MultioptionBase, form_widget);

    MultioptionBase.prototype.parseValue = function (value) {
        return value;
    };

    MultioptionBase.prototype.contextCatalogs = function () {
        if (!this.context.catalogs) {
            this.context.catalogs = {};
        }
        return this.context.catalogs;
    }

    // this is a template method
    MultioptionBase.prototype.buildOptions = function (data) {
        var self = this;

        self.inputs = [];
        self.labels = [];

        var value = self.storage_key && self.context.control_storage &&
            self.context.control_storage[self.storage_key] ||
            self.value;

        value = value && self.parseValue(value);

        for (var i in data) {
            if (data.hasOwnProperty(i)) {
                var opt = data[i];
                var optvalue = (opt.value && opt.text) ? opt.value
                                                       : (opt.val && opt.val[0] && opt.val[1]) ? opt.val[0]
                                                                                               : opt;
                var optlabel = opt.text || (opt.val && opt.val[0] && opt.val[1] ? opt.val[1] : opt);

                var label = document.createElement('label');
                label.className = "control-label";
                label.setAttribute("for", "prisma_field_" + self.id + "_" + +i);

                var input = self.createInput(i, opt, value, optvalue); // implemented by child class

                // adds aria-label in multiple options
                input.ariaLabel = optlabel;

                label.appendChild(input);

                if (self.field && self.field['switch-style']) {
                    var switchSlider = document.createElement("span");
                    switchSlider.className = "prisma-switch-slider";
                    label.appendChild(switchSlider);
                }

                var span = document.createElement('span')
                span.innerHTML = optlabel;
                label.appendChild(span);

                var itemContainer = document.createElement('div');
                itemContainer.className = self.itemContainerClass;

                if (self.enabled === false) {
                    itemContainer.clasName += " disabled"; // needed?
                    label.className += " disabled";
                    input.className += " disabled";
                }

                itemContainer.appendChild(label);
                self.containerWrapper.appendChild(itemContainer);

                self.labels.push(label); // ojo que el codigo viejo hace push de itemContainer
                self.inputs.push(input);

                (function (e, value) {
                    prisma.setEvent(e, "change", function () {
                        if (e.checked) {
                            if (self.srctype === "catalog") {
                                update_catalog_dependencies(self.context, self.catalog.id, value);
                            }
                            self.fireEvent("onChange", value);
                        }
                    });
                })(input, optvalue);
            }
        }
    };

    MultioptionBase.prototype.create = function () {
        var self = this;

        self.containerWrapper = document.createElement('div');
        self.containerWrapper.className = self.containerClass;
        self.containerWrapper.id =  self.id;

        if (self.srctype === 'catalog' && self.catalog && self.catalog.id) {
            var catalogs = self.contextCatalogs();
            catalogs[self.catalog.id] = catalogs[self.catalog.id] || [];
            catalogs[self.catalog.id].push(self); // ??

            if (self.catalog['parent-id']) {
                catalogs[self.catalog['parent-id']] = catalogs[self.catalog['parent-id']] || [];
                catalogs[self.catalog['parent-id']].push(self);
            } else {
                get_catalog_data(self.context,
                                 self.catalog.id,
                                 null,
                                 function (response) {
                                     self.buildOptions(response.val);
                                     //if (self.cardinality === "one") {
                                         update_catalog_dependencies(self.context, self.catalog.id, self.value);
                                     //}
                                 },
                                 function () {});
            }
        } else if (self.options) {
            self.buildOptions(self.options);
        }

        self.domNode = self.form_layout(self.containerWrapper);

        if (self.visible) {
            prisma.removeClass(self.domNode, "hidden")
        } else {
            prisma.addClass(self.domNode, "hidden")
        }

        return self.domNode;
    };

    MultioptionBase.prototype.setEnabled = function(value) {
        MultioptionBase.SUPERclass.setEnabled.call(this, value);
        for (var i = 0; i < this.inputs.length; ++i) {
            this.inputs[i].disabled = !this.enabled;
            if (this.enabled) {
                prisma.removeClass(this.labels[i], "disabled");
                prisma.removeClass(this.inputs[i], "disabled");
            } else {
                prisma.addClass(this.labels[i], "disabled");
                prisma.addClass(this.inputs[i], "disabled");
            }
        }
    };

    MultioptionBase.prototype.destroy = function () {
        if (this.storage_key) {
            this.context.control_storage = this.context.control_storage || {};
            this.context.control_storage[this.storage_key] = this.getValue();
        }
    };

    MultioptionBase.prototype.onChange = function (fn){
        this.addListener("onChange", fn);
    };

    MultioptionBase.prototype.retrieveData = function (cb) {
        var self = this;
        var value = self.getValue();
        if (value == null && self.required && self.visible) {
            self.displayError(self._T(":funnel-navigation", ":errors-option-required"));
            cb(false);
        } else {
            self.displayError(null);
            self.validate(value, function () {
                cb(true, value);
            }, function (err) {
                self.displayError(err);
                cb(false);
            })
        }
    };

    MultioptionBase.prototype.updateCatalogOptions = function (key) {
        var self = this;
        self.inputs.forEach(function (e) {
            self.containerWrapper.removeChild(e.parentNode.parentNode);
        });
        self.inputs = [];
        self.labels = [];
        if (key !== null && key !== '' && key !== undefined) {
            get_catalog_data(self.context,
                             self.catalog.id,
                             key,
                             function (response) {
                                 self.buildOptions(response.val);
                                 update_catalog_dependencies(self.context, self.catalog.id, self.value);
                             },
                             function () {});
        } else {
            self.value = null;
            self.fireEvent("onChange", null);
            update_catalog_dependencies(self.context, self.catalog.id, null);
        }
    };


    function MultipleChoice(context, opts, childs) {
        multioption.SUPERconstructor.call(this, context, opts, childs);
        this.cardinality = "many";
        this.containerClass = '';
        this.itemContainerClass = 'prisma-checkbox-component';
        if (this.field && this.field['switch-style']) {
            this.itemContainerClass += ' prisma-checkbox-switch';
        }
    }
    inherit(MultipleChoice, MultioptionBase);

    MultipleChoice.prototype.getValue = function () {
        var self = this;
        console.info("will get value:");

        var value = this.inputs.reduce(function (m, e) {
            if (e.checked) {
                var value = e.getAttribute("value");
                if (value === "prisma.other") {
                    e = document.getElementById(e.id + "_txt");
                    if (self.isNumber()) {
                        value = (e && e.value || "");
                    } else {
                        // prefixed in case nothing is written in the text area
                        value += ":" + (e && e.value || "");
                    }
                }
                if (value) {
                    m.push(value)
                }
            }
            return m;
        }, []);

        if (value.length === 0) {
            return null;
        }

        return value;
    };

    MultipleChoice.prototype.createInput = function (i, opt, value, optvalue) {
        var chk = document.createElement("input");
        chk.type = "checkbox";
        chk.id = "prisma_field_" + this.id + "_" + i;
        chk.disabled = (this.enabled === false);
        chk.name = "prisma_field_" + this.id;
        chk.checked = Array.isArray(value) && value.indexOf(optvalue) >= 0;
        chk.setAttribute("value", optvalue);

        if (Array.isArray(this.tempValue)) {
            var j = this.tempValue.indexOf(optvalue);
            if (j >= 0) {
                this.tempValue.splice(j, 1);
            }
        }

        return chk;
    };

    MultipleChoice.prototype.isNumber = function (data) {
        return [":long", ":bigdec", ":currency"].indexOf(this.field['column-type']) >= 0;
    }

    MultipleChoice.prototype.buildOptions = function (data) {
        var self = this;

        this.tempValue = this.value && this.parseValue(this.value);
        MultipleChoice.SUPERclass.buildOptions.call(this, data);

        if (this.field && this.field['add-option-other']) {
            var label = document.createElement('label');
            label.className = "control-label";
            label.setAttribute("for", "prisma_field_" + self.id + "_" + this.inputs.length);

            var otherValue = undefined;
            var isOtherChecked = false; // in case it's checked but nothing has been filled
            if (this.tempValue && this.tempValue.length > 0) {
                var elem = this.tempValue.find(function(val) {
                    return val.indexOf("prisma.other:") === 0;
                });
                if (elem) {
                    isOtherChecked = true;
                    otherValue = elem.substring("prisma.other:".length);
                } else if (self.isNumber()) {
                    if (this.tempValue.length > 1) {
                        console.warn("More than one value do not match the provided options, choosing the first one as 'other'");
                    }
                    // number values do not have the prefix
                    isOtherChecked = true;
                    otherValue = this.tempValue[0];
                }
            }

            var input = self.createInput(this.inputs.length, {}, isOtherChecked ? ["prisma.other"] : [], "prisma.other");
            var span = document.createElement('span')
            var txt_input_option = (this.field['other-option-label'] !== undefined &&
                                   prisma.trim(this.field['other-option-label']) != '') ? this.field['other-option-label'] : "Other";
            input.ariaLabel = txt_input_option;
            span.innerHTML = txt_input_option;
            var txt_input = document.createElement("input");
            txt_input.className = "form-control prisma-add-other-component";
            txt_input.type = self.isNumber() ? "number" : "text";
            txt_input.id = input.id + "_txt";
            txt_input.ariaLabel = txt_input_option;

            if (otherValue) {
                txt_input.setAttribute("value", otherValue);
            }
            label.append(input);
            if (self.field && self.field['switch-style']) {
                var switchSlider = document.createElement("span");
                switchSlider.className = "prisma-switch-slider";
                label.appendChild(switchSlider);
            }

            // @nartub txt_input is hidden due to CSS rule
            label.append(span, txt_input);

            var itemContainer = document.createElement('div');
            itemContainer.className = self.itemContainerClass;

            if (self.enabled === false) {
                itemContainer.clasName += " disabled"; // needed?
                label.className += " disabled";
                input.className += " disabled";
            }

            itemContainer.appendChild(label);
            self.containerWrapper.appendChild(itemContainer);

            self.labels.push(label); // ojo que el codigo viejo hace push de itemContainer
            self.inputs.push(input);
        }
    };

    function multioption(context, opts, childs) {
        multioption.SUPERconstructor.call(this, context, opts, childs);
        this.inputs = [];
        this.system_enabled = true;
        this.user_enabled = true;
        this.containerClass = 'prisma-radiobutton-component';
        this.itemContainerClass = 'radio';
        if (this.controlStyle) {
            switch (this.controlStyle) {
                case ':horizontal':
                    this.containerClass += ' prisma-radiobutton-horizontal';
                    break;
                case ':segmented':
                    this.containerClass += ' prisma-radiobutton-segmented';
                    break;
            }
        }
    }
    inherit(multioption, MultioptionBase);

    multioption.prototype.getValue = function () {
        for (var optindex = 0; optindex < this.inputs.length; optindex++) {
            var opt = this.inputs[optindex];
            if (opt.checked) {
                return opt.getAttribute("value");
            }
        }
        return null;
    };

    multioption.prototype.createInput = function (i, opt, value, optvalue) {
        var rb = document.createElement("input");
        rb.type = "radio";
        rb.id = "prisma_field_" + this.id + "_" + i;
        rb.disabled = (this.enabled === false);
        rb.name = "prisma_field_" + this.id;
        rb.ariaLabel = this.label;
        rb.checked = prisma.valueToString(optvalue) === prisma.valueToString(value) ||
                     prisma.valueToNumber(optvalue) === prisma.valueToNumber(value);
        rb.setAttribute("value", optvalue);
        if (prisma.valueToString(optvalue) === prisma.valueToString(value) ||
            prisma.valueToNumber(optvalue) === prisma.valueToNumber(value)){
            this.match = optvalue;
        }
        return rb;
    };

    multioption.prototype.buildOptions = function (data) {
        multioption.SUPERclass.buildOptions.call(this, data);
        if (this.value !== this.match) {
            this.fireEvent("onChange", this.match);
        }
        this.value = this.match;
        this.match = undefined;
    };

    multioption.prototype.setEnabled = function (value, internalCall) {
        if (internalCall) {
            this.system_enabled = value;
            if (value == true && this.user_enabled == false)
                return;
        } else {
            this.user_enabled = value;
            if(this.system_enabled == false && value == true)
                return;
        }

        multioption.SUPERclass.setEnabled.call(this, value);
    };

    prisma.multioption = multioption;

    /** @constructor */
    function checkbox(context, opts, childs) {
        checkbox.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(checkbox, form_widget);
    checkbox.prototype.create = function () {
        var frmgroup;
        var self = this;
        var checkboxDivComponent = document.createElement("div");
        checkboxDivComponent.className = "prisma-checkbox-component";
        checkboxDivComponent.id = this.id;
        var checkbox = document.createElement("input");
        checkbox.type = "checkbox";
        var value = this.value;
        if (this.storage_key &&
            this.context.control_storage &&
            this.context.control_storage[this.storage_key])
            value = this.context.control_storage[this.storage_key];
        checkbox.checked = prisma.valueToBoolean(value);
        checkbox.id = "prisma_field_" + this.id;

        // adds arialabel to simple checkbox.
        checkbox.ariaLabel = this.label;

        this.checkbox = checkbox;
        prisma.setEvent(checkbox, "change", function (e) {
            self.fireEvent("onChange",self.checkbox.checked ? true : false)
        });
        if (this.form_alignment !== "vertical") {
            var checkboxLabel = document.createElement("label");
            checkboxLabel.className = "control-label control-label-custom";
            checkboxLabel.appendChild(checkbox);
            checkboxDivComponent.appendChild(checkboxLabel);
            if (self.field && self.field['switch-style']) {
                var switchSlider = document.createElement("span");
                switchSlider.className = "prisma-switch-slider";
                checkboxLabel.appendChild(switchSlider);
                checkboxDivComponent.className += " prisma-checkbox-switch";
            }
            frmgroup = this.form_layout(checkboxDivComponent);
        } else {
            frmgroup = this.form_layout(checkbox, {
                mode: "checkbox"
            });
            frmgroup.className = "prisma-checkbox-component";
            if (self.field && self.field['switch-style']) {
                frmgroup.className += " prisma-checkbox-switch";
            }
        }
        if (self.enabled === false) {
            self.setEnabled(false);
        }
        if (typeof this.title === "string") {
            this.label.title = this.title;
        }
        this.label.setAttribute("for", "prisma_field_" + this.id);
        this.domNode = frmgroup;
        return frmgroup;
    }
    checkbox.prototype.setEnabled = function (value) {
        checkbox.SUPERclass.setEnabled.call(this, value);
        this.checkbox.disabled = !this.enabled;
        if (this.enabled) {
            prisma.removeClass(this.label, "disabled")
        } else {
            prisma.addClass(this.label, "disabled");
        }
    }

    checkbox.prototype.setValue = function (value) {
        if(this.checkbox){
            this.checkbox.checked = prisma.valueToBoolean(value);
        }
    }
    checkbox.prototype.retrieveData = function (cb) {
        if (this.required && !this.checkbox.checked && this.visible) {
            this.displayError(this._T(":funnel-navigation", ":errors-field-required"));
            cb(false);
        } else {
            this.displayError(null);
            cb(true, this.checkbox.checked ? true : false);
        }
    }
    checkbox.prototype.destroy = function () {
        if (this.storage_key) {
            this.context.control_storage = this.context.control_storage || {};
            this.context.control_storage[this.storage_key] = this.checkbox.checked ? true : false;
        }
    }

    checkbox.prototype.onChange = function (fn){
        this.addListener("onChange",fn);
    }
    prisma.checkbox = checkbox;

    function binaryUpload(context, opts, childs) {
        binaryUpload.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(binaryUpload, form_widget);

    binaryUpload.prototype.create = function () {
        var self = this;
        var controlNode = document.createElement("div");
        controlNode.className = "prisma-upload-component";
        controlNode.id = this.id;
        var uploadButtonWrapper = document.createElement("div");
        uploadButtonWrapper.className = "prisma-button-component";
        var uploadLabel = document.createElement("label");
        uploadLabel.setAttribute("for", this.id + "_input");
        uploadLabel.className = "btn btn-default";
        uploadLabel.innerHTML = "<span class='public-icn-upload prisma-icon'></span>Upload";
        uploadButtonWrapper.appendChild(uploadLabel);
        uploadLabel.onclick = function () {
            dropzoneNode.click();
        }
        var uploadComponent = document.createElement("span");
        uploadComponent.className = "prisma-upload-feedback";
        controlNode.appendChild(uploadButtonWrapper);
        controlNode.appendChild(uploadComponent);
        var setIcon = function (icon) {
            var uploadIcon = self.uploadComponent.parentElement.getElementsByClassName("prisma-icon")[0];
            uploadIcon.className = icon + " prisma-icon";
        }
        var dropzoneControl = new prisma.dropzoneControl(this.context, {}, [])
        dropzoneControl.id = self.id;
        dropzoneControl.cardinality == ":one";
        dropzoneControl.onAddedFile = function (file) {

        }
        dropzoneControl.onRemovedFile = function (file) {

        }
        dropzoneControl.onUploaded = function (file, response) {
            var response = jsedn.parse(response);
            self.fileReference = response.at(jsedn.kw(":id"));
            self.fileName = response.at(jsedn.kw(":filename"));
            self.showUploadedOK();
            setIcon("public-icn-upload");
        }
        dropzoneControl.onError = function (file, errorMessage) {
            if (window.console) console.log("upload error" + errorMessage);
            setIcon("public-icn-upload");
            self.showUploadError(errorMessage);
        }

        var dropzoneNode = dropzoneControl.createDropzoneNode(controlNode);
        dropzoneNode.setAttribute("style", "display:none");
        controlNode.appendChild(dropzoneNode);

        self.uploadComponent = uploadComponent;
        var value = this.value;
        if (this.storage_key &&
            this.context.control_storage &&
            this.context.control_storage[this.storage_key]) {
            value = this.context.control_storage[this.storage_key];
        }
        self.resolveFile(value);
        var frmgroup = self.form_layout(controlNode);
        this.domNode = frmgroup;
        if(self.visible===false)
            self.setVisible(false);
        if(self.enabled===false)
            self.setEnabled(false);
        return frmgroup;
    }

    binaryUpload.prototype.showUploadError = function (errorMessage) {
        var successMsgs = this.uploadComponent.getElementsByClassName("public-icn-success");
        for (var i = 0; i < successMsgs.length; i++) {
            successMsgs[i].parentElement.removeChild(successMsgs[i]);
        }
        var errorMsgs = this.uploadComponent.getElementsByClassName("text-danger");
        for (var i = 0; i < errorMsgs.length; i++) {
            errorMsgs[i].parentElement.removeChild(errorMsgs[i]);
        }
        var errorNode = document.createElement("span");
        errorNode.className = "text-danger";
        errorNode.innerHTML = "<span class='public-icn-error'></span>Error " + errorMessage ? errorMessage : "";
        this.uploadComponent.appendChild(errorNode);
    }

    binaryUpload.prototype.showUploadedOK = function () {
        this.uploadComponent.innerHTML = "<span class='public-icn-success'><span class=''></span></span>";
        this.loadFilePreview();
    }

    binaryUpload.prototype.resolveFile = function (fileReference) {
        var self = this;
        if (!fileReference) return;
        self.context.api.get(self.context.api.getUrl() + "sdk/file/" + fileReference, [],
            function (response) {
                var response = jsedn.parse(response);
                self.fileReference = response.at(jsedn.kw(":id"));
                self.fileName = response.at(jsedn.kw(":filename"));
                self.loadFilePreview();
            },
            function (error) {

            });
    }

    binaryUpload.prototype.loadFilePreview = function () {
        var f = document.createElement("div");
        f.className = "prisma-button-component";
        var link = document.createElement("a");
        link.className = "btn btn-link btn-sm upload-file";
        link.setAttribute("href", "/sdk/download/" + this.fileReference);
        link.setAttribute("target", "_blank");
        link.innerHTML = this.fileName;
        f.appendChild(link);
        this.uploadComponent.insertBefore(f, this.uploadComponent.childNodes[0]);
    }

    binaryUpload.prototype.retrieveData = function (cb) {
        if (this.fileReference) {
            this.displayError(null);
            cb(true, this.fileReference);
        } else {
            if (this.required && this.visible) {
                this.displayError(this._T(":funnel-navigation", ":errors-fields-required"));
                cb(false);
            } else {
                this.displayError(null);
                cb(true);
            }
        }
    }

    binaryUpload.prototype.destroy = function () {
        if (this.storage_key) {
            this.context.control_storage = this.context.control_storage || {};
            this.context.control_storage[this.storage_key] = this.fileReference;
        }
    }
    binaryUpload.prototype.onChange = function fn(){
        this.addListener("onChange",fn)
    }

    prisma.binaryUpload = binaryUpload;


    function textinput(context, opts, childs) {
        textinput.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(textinput, form_widget);
    textinput.prototype.create = function () {
        var self = this;
        var txinput = document.createElement(this.multiline ? "textarea" : "input");
        txinput.className = "form-control" + " " + (this.class || "");
        txinput.id = this.id;
        this.txinput = txinput;

        this.txinput.ariaLabel = this.label;

        var value = this.value;
        if (this.storage_key &&
            this.context.control_storage &&
            this.context.control_storage[this.storage_key])
            value = this.context.control_storage[this.storage_key];


        var s = (this.font ? (this.font["font-family"] ? "font-family:" + this.font["font-family"] + ";" : "") +
                (this.font["font-style"] ? "font-style:" + this.font["font-style"] + ";" : "") +
                (this.font["font-size"] ? "font-size:" + this.font["font-size"] + "px;" : "") +
                (this.font["font-weight"] ? "font-weight:" + this.font["font-weight"] + ";" : "") +

                (this.font["color"] ? "color:" + this.font["color"] + ";" : "") : "") +
            (this.font && this.font.align ? "text-align:" + this.font.align + ";" : "") +
            (this["min-width"] ? "min-width:" + this["min-width"] + ";" : "")+
            (this["margin-left"] ? "margin-left:" + this["margin-left"] + ";" : "")+
            (this["margin-right"] ? "margin-right:" + this["margin-right"] + ";" : "")



        txinput.setAttribute("style", s);
        if (this["auto-complete"] === "off"){
            if (prisma.getBrowser().name == "Chrome")
                txinput.setAttribute("autocomplete","autocomplete_off_hack")
            else
                txinput.setAttribute("autocomplete","off")
        }


        if (this.maxLength && !isNaN(Number(this.maxLength))) {
            this.maxLength = Number(this.maxLength);
        } else
            this.maxLength = null;

        txinput.setAttribute("style", s);

        switch (this.mask) {
            case "date": {
                var fireOnChange = function () {
                    var value = prisma.trim(self.txinput.value);
                    var trv = prisma.trim(value.replace(new RegExp("_", "g"), ""));
                    if (trv.length == 10) {
                        v = trv.split("/");
                        if (v.length < 3)
                            value = new Date(Date.parse(trv));
                        else
                            value = new Date(Date.parse(v[2] + "-" + v[1] + "-" + v[0]));
                        if (!isNaN(value)) {
                            value = new Date(Date.UTC(value.getUTCFullYear(),
                                value.getUTCMonth(),
                                value.getUTCDate(),
                                0,
                                0,
                                0));
                            self.fireEvent("onChange", value)
                        }
                    }
                }

                txinput.setAttribute("type", "date")
                if (txinput.getAttribute("type") != "date") {
                    prisma.textinput_mask(txinput, "date", {},
                        fireOnChange,
                        function () { })
                        (prisma.valueToString(value));
                } else {
                    if(value!==null && value !== undefined)
                        txinput.valueAsDate = value;
                    prisma.setEvent(txinput, "input", fireOnChange);
                }

            }

                break;
            case "numeric":
                txinput.setAttribute("type", "number")
                if (txinput.getAttribute("type") != "number") {
                    prisma.textinput_mask(txinput, "numeric", {
                        decimals: this.decimals,
                        allowNegative: this.decimals,
                        decimalSeparator: this.decimalSeparator,
                        currency: this.currency,
                        thousandsSeparator: this.thousandsSeparator,
                        maxLength: this.maxLength

                    },
                        function (v) {
                            self.fireEvent("onChange", v)
                        },
                        function () { })
                        (prisma.valueToString(value));


                } else {
                    txinput.value = value;
                    prisma.setEvent(txinput, "input", function (e) {
                        if (self.maxLength > 0) {                // HACK para hacer maxlength con html5
                            txinput.value = txinput.value.slice(0, self.maxLength);
                        }
                        var r;
                        if(self.decimals > 0)
                            r = Number(txinput.value);
                        else
                            r = txinput.value;
                        if(!isNaN(r)){
                            self.fireEvent("onChange", r);
                        }else{
                            self.fireEvent("onChange",null);
                        }
                    })

                    if(this.decimals == 0){
                        prisma.setEvent(txinput, "keydown", function (e) {
                            if(e.keyCode == 69 || e.keyCode == 190 || e.keyCode == 189)
                              e.preventDefault();

                        })
                    }
                }
                break;
            case "password":
                prisma.textinput_mask(txinput, "password", {
                    maxLength: this.maxLength
                },
                    function (v) {
                        self.fireEvent("onChange", v)
                    },
                    function () { })
                    (prisma.valueToString(value));
                break;
            default:
                prisma.textinput_mask(txinput, this.mask || "", {
                    maxLength: this.maxLength
                },
                    function (v) {
                        self.fireEvent("onChange", v)
                    },
                    function () { })
                    (prisma.valueToString(value));
        }
        var frmgroup = this.form_layout(txinput);
        this.domNode = frmgroup;
        if (self.visible === false)
            self.setVisible(false);
        if (self.enabled === false)
            self.setEnabled(false);

        return frmgroup;
    }
    textinput.prototype.setValue = function (value) {
        if(this.txinput){
            if(value && value.getFullYear)
                this.txinput.valueAsDate = value
            else
                this.txinput.value = prisma.valueToString(value);
        }
    }

    textinput.prototype.retrieveData = function (cb) {
        var self = this;
        var value = prisma.trim(this.txinput.value);
        if (this.mask == "date") {
            var trv = prisma.trim(value.replace(new RegExp("_", "g"), ""));
            if (value == '') {
                if (this.required && this.visible) {
                    this.displayError(this._T(":funnel-navigation", ":errors-fields-required"));
                    cb(false);
                } else {
                    cb(true, null)
                }
            } else if (trv.length == 10) {
                v = trv.split("/");
                if (v.length < 3)
                    value = new Date(Date.parse(trv));
                else
                    value = new Date(Date.parse(v[2] + "-" + v[1] + "-" + v[0]));
                if (isNaN(value)) {
                    this.displayError(this._T(":funnel-navigation", ":errors-invalid-date"));
                    cb(false);
                } else {
                    var localvalue = new Date(value.getUTCFullYear(),
                        value.getUTCMonth(),
                        value.getUTCDate(),
                        0,
                        0,
                        0);
                    value = new Date(Date.UTC(value.getUTCFullYear(),
                        value.getUTCMonth(),
                        value.getUTCDate(),
                        0,
                        0,
                        0));
                    this.displayError(null);
                    self.validate(localvalue, function () {
                        self.displayError(null);
                        cb(true, value);
                    }, function (err) {
                        self.displayError(err)
                        cb(false)
                    });
                }
            } else {
                this.displayError(this._T(":funnel-navigation", ":errors-invalid-date"));
                cb(false);
            }
        } else {
            var v = value
            if (self.mask == "numeric") {
                if (prisma.trim(value) !== "") {
                    var r = Number(prisma.trim(value))
                    if (!isNaN(r)) {
                        if (self.field["db-type"] === ":db.type/string") {
                            v = prisma.trim(value);
                        } else {
                            v = r;
                        }
                    } else {
                        v = null;
                    }
                } else {
                    v = null;
                }
            }

            self.validate(v,
                function () {
                    self.displayError(null);
                    cb(true, v);

                },
                function (err) {
                    self.displayError(err);
                    cb(false);
                })
        }
    }
    textinput.prototype.getText = function () {
        return prisma.trim(this.txinput.value);
    }

    textinput.prototype.setText = function (v){
        this.txinput.value = v;
    }

    textinput.prototype.setEnabled = function (value) {
        textinput.SUPERclass.setEnabled.call(this, value);
        this.txinput.disabled = !this.enabled;
        if (this.enabled) {
            prisma.removeClass(this.label, "disabled");
        } else {
            prisma.addClass(this.label, "disabled");
        }
    }
    textinput.prototype.destroy = function () {
        if (this.storage_key) {
            this.context.control_storage = this.context.control_storage || {};
            this.context.control_storage[this.storage_key] = this.txinput.value;
        }
    }
    textinput.prototype.onChange = function (fn ){
        this.addListener("onChange",fn);
    }

    textinput.prototype.setFocus = function (){
        this.txinput.focus();
    }
    prisma.textinput = textinput;

    function button(context, opts, childs) {
        button.SUPERconstructor.call(this, context, opts, childs);
        context.action_buttons = context.action_buttons || [];
        if (this.action && this.action != ":none") {
            context.action_buttons.push(this);
        }
    }
    inherit(button, form_widget);
    button.prototype.create = function () {
        var self = this;
        var buttonWrapper = document.createElement("div");
        var wrapperClass = "prisma-button-component";
        if (this.align == "left") {
            wrapperClass += " text-left";
        } else if (this.align == "center") {
            wrapperClass += " text-center";
        } else if (this.align == "right") {
            wrapperClass += " text-right";
        }
        buttonWrapper.className = wrapperClass;;
        buttonWrapper.id = this.id;
        var n = document.createElement("button");
        var className = this.class ? this.class : this.className ? this.className : "btn btn-default";
        n.className = className;
        var width = "auto";
        var height = "auto";
        if (this.size && this.size.width)
            width = this.size.width;
        if (this.size && this.size.height)
            height = this.size.height;

        var s = "width:" + width + ";" +
            "height:" + height + ";" +
            (this.border ? "border: " + this.border.width + " solid " + (this.border.color || "black") + ";" : "") +
            (this.background ? "background: " + this.background + ";" : "") +
            (this.font ? (this.font["font-family"] ? "font-family:" + this.font["font-family"] + ";" : "") +
                (this.font["font-style"] ? "font-style:" + this.font["font-style"] + ";" : "") +
                (this.font["font-size"] ? "font-size:" + this.font["font-size"] + "px;" : "") +
                (this.font["font-weight"] ? "font-weight:" + this.font["font-weight"] + ";" : "") +
                (this.font["color"] ? "color:" + this.font["color"] + ";" : "") : "") +
            (this.verticalMargin ? "margin-top:" + this.verticalMargin + ";" : "") +
            (this.verticalMargin ? "margin-bottom:" + this.verticalMargin + ";" : "") +
            (this.horizontalMargin ? "margin-left:" + this.horizontalMargin + ";" : "") +
            (this.horizontalMargin ? "margin-right:" + this.horizontalMargin + ";" : "") +
            (this.font && this.font.align ? "text-align:" + this.font.align + ";" : "") +
            (this["border-radius"] ? "border-radius:" + this["border-radius"] + "px;" +
                "-webkit-border-radius: " + this["border-radius"] + "px;" +
                "-moz-border-radius: " + this["border-radius"] + "px;" : "");

        n.setAttribute("style", s);

        if (this.ariaLabel)
          n.ariaLabel = this.ariaLabel;
        if (this.enabled === false)
            n.setAttribute("disabled", "");
        if (this.action && this.action !== ":none") {
            var action = new jsedn.Map([jsedn.kw(":funnel-step-s12n-action/type"), jsedn.kw(this.action),
                                        jsedn.kw(":funnel-step-s12n-action/id"), this.id || ""]);
            if (this.action == ":funnel-step-s12n-action-type/next" && !this.className){
                className = "btn btn-primary"
                n.className = className;
            }
            if (!this.context.view.isActionVisible(action)) {
                n.style.visibility = "hidden";
            }
            if (!this.context.view.isActionEnabled(action)) {
                n.setAttribute("disabled", "disabled");
            }

            prisma.setEvent(n, "click", function (e) {
                var r = self.fireEvent("click", e)
                if (r === false)
                    return;
                self.setEnabled(false);
                self.context.view.funnel.onAction(action, function (status) {
                    self.setEnabled(true);
                });
            })
        } else {
            prisma.setEvent(n, "click",
                function (e) {
                    self.fireEvent("click", e);
                });
        }


        if (this.link) {
            prisma.setEvent(n, "click",
                function (e) {
                    window.open(self.link, '_blank');
                });
        }

        if (this.icon) {
            var icon = document.createElement("span");
            icon.className = this.icon;
            n.appendChild(icon);
        }

        if (this.text) {
            var lb = document.createTextNode(this.render_translations(this.text || "Button"));
            this.labelNode = lb;
            n.appendChild(lb);
        }

        this.button = n;
        buttonWrapper.appendChild(n);
        this.domNode = buttonWrapper;
        if(self.visible===false)
            self.setVisible(false);
        if(self.enabled===false)
            self.setEnabled(false);

        return buttonWrapper;
    };

    button.prototype.setEnabled = function (value) {
        button.SUPERclass.setEnabled.call(this, value);
        this.button.disabled = !this.enabled;
        if (this.enabled) {
            prisma.removeClass(this.button, "disabled")
        } else {
            prisma.addClass(this.button, "disabled")
        }
    };

    button.prototype.setVisible = function (value) {
        button.SUPERclass.setVisible.call(this, value);
        this.button.visible = !this.visible;
        if (this.visible) {
            prisma.removeClass(this.button, "hidden")
        } else {
            prisma.addClass(this.button, "hidden")
        }
    };

    button.prototype.setText = function (text) {
        this.labelNode.nodeValue = text;
    }

    button.prototype.onClick = function (fn){
        this.addListener("click",fn)
    }
    button.prototype.setFocus = function (){
        this.button.focus();
    }

    prisma.button = button;

    function hline(context, opts, childs) {
        hline.SUPERconstructor.call(this, context, opts, childs);
    }

    inherit(hline, widget);
    hline.prototype.create = function () {
        var self = this;
        var n = document.createElement("hr");
        n.id = this.id;
        var s = "border: none;" +
            "border-bottom: " + (this.border ? (this.border.width || "1px") : "1px") + " " +
            (this.style || "solid") + " " +
            (this.border ? (this.border.color || "#A0A0A0") : "#A0A0A0");

        n.setAttribute("style", s);
        this.domNode = n;
        return n;
    }
    prisma.hline = hline;

    function title(context, opts, childs) {
        title.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(title, widget);

    title.prototype.create = function () {
        var n = document.createElement("H1");
        n.setAttribute("class", "prisma-title-component");
        n.id = this.id;
        var s = (this.padding ? "padding: " + this.padding + ";" : "") +
                "line-height:" + (this["line-height"] || "1.42em") + ";" +
                (this.font ? (this.font["font-family"] ? "font-family:" + this.font["font-family"] + ";" : "") +
                (this.font["font-style"] ? "font-style:" + this.font["font-style"] + ";" : "") +
                (this.font["font-size"] ? "font-size:" + this.font["font-size"] + "px;" : "") +
                (this.font["font-weight"] ? "font-weight:" + this.font["font-weight"] + ";" : "") +
                (this.font["color"] ? "color:" + this.font["color"] + ";" : "") : "") +
            (this.font && this.font.align ? "text-align:" + this.font.align + ";" : "")
        n.setAttribute("style", s);
        var lb = document.createElement("div");
        this.lb = lb;
        this.setText(this.text == undefined ? "Title" : this.render_translations (this.text));
        n.appendChild(lb);
        this.domNode = n;
        if(self.visible===false)
            self.setVisible(false);

        return n;
    }
    title.prototype.setText = function (newText) {
        this.text = newText;
        this.lb.innerHTML = ('' + newText).replace(/\\n/g, "<br />");
    }
    title.prototype.getText = function () {
        return this.text;
    }

    prisma.title = title;

    function label(context, opts, childs) {
        label.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(label, widget);

    label.prototype.create = function () {
        var n = document.createElement("p");
        n.setAttribute("class", "prisma-label-component");
        n.id = this.id;
        var s = (this.padding ? "padding: " + this.padding + ";" : "") +
                "line-height:" + (this["line-height"] || "1.42em")+ ";" +
                (this.font ? (this.font["font-family"] ? "font-family:" + this.font["font-family"] + ";" : "") +
                (this.font["font-style"] ? "font-style:" + this.font["font-style"] + ";" : "") +
                (this.font["font-size"] ? "font-size:" + this.font["font-size"] + "px;" : "") +
                (this.font["font-weight"] ? "font-weight:" + this.font["font-weight"] + ";" : "") +
                (this.font["color"] ? "color:" + this.font["color"] + ";" : "") : "") +
            (this.font && this.font.align ? "text-align:" + this.font.align + ";" : "")


        n.setAttribute("style", s);
        var lb = document.createElement("div");
        this.lb = lb;
        this.setText(this.text == undefined ? "Label" : this.render_translations (this.text));
        n.appendChild(lb);
        this.domNode = n;
        if(self.visible===false)
            self.setVisible(false);
        return n;
    }
    label.prototype.setText = function (newText) {
        this.text = newText;
        this.lb.innerHTML = ('' + newText).replace(/\\n/g, "<br />");
    }
    prisma.label = label;

    function recaptcha_v2(context, opts, childs) {
        recaptcha_v2.SUPERconstructor.call(this, context, opts, childs);
        this["can-be-hidden"] = false;
    }
    inherit(recaptcha_v2, form_widget);

    recaptcha_v2.prototype.create = function () {
        var self = this;
        var n = document.createElement("div");
        n.className = "prisma-recaptcha-component";
        n.id = this.id;

        this.formgroup = document.createElement("div");
        this.formgroup.className = "form-group";
        var captcha_placeholder = document.createElement("div");
        prisma.loadScript("https://www.google.com/recaptcha/api.js",
            "grecaptcha",
            function (status) {
                if (status) {
                    grecaptcha.ready(function () {
                        grecaptcha.render(captcha_placeholder, {
                            sitekey: self["google-captcha-v2-site-key"] || "_invalid_key_",
                            theme: self["theme"] || "light",
                            size: self["size"] || "normal"
                        })
                    });
                }
            });
        this.formgroup.appendChild(captcha_placeholder);

        var align = " prisma-recaptcha-align-left";
        switch (this.align) {
            case "left":
                align = " prisma-recaptcha-align-left";
                break;
            case "center":
                align = " prisma-recaptcha-align-center";
                break;

            case "right":
                align = " prisma-recaptcha-align-right";
                break;
        }
        captcha_placeholder.className = align;
        this.error_wrapper_node = document.createElement("div");
        this.error_wrapper_node.className = "error align-top";
        this.error_node = document.createElement("p");
        this.error_wrapper_node.appendChild(this.error_node);
        this.formgroup.appendChild(this.error_wrapper_node);
        n.appendChild(this.formgroup);
        this.domNode = n;
        return n;
    }
    recaptcha_v2.prototype.displayError = function (err) {
        if (err) {
            prisma.addClass(this.formgroup, "has-error");
            prisma.addClass(this.formgroup, "fixed");
            this.error_node.innerHTML = err;
        } else {
            this.error_node.innerHTML = "";
            prisma.removeClass(this.formgroup, "has-error");
            prisma.removeClass(this.formgroup, "fixed");
        }
    }
    recaptcha_v2.prototype.retrieveData = function (cb) {
        var r = grecaptcha.getResponse();
        if (r == "") {
            this.displayError(this._T(":funnel-navigation", ":errors-fill-field"));
        } else {
            this.displayError(null);
        }
        cb(r != "", r);
    }
    prisma.recaptcha_v2 = recaptcha_v2;
    prisma.re_captcha = recaptcha_v2;

    function languageSelector(context, opts, childs) {
        languageSelector.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(languageSelector, form_widget);
    languageSelector.prototype.langIdToString = function (value) {
        switch (value) {
            case ":en":
                return "English";
            case ":es":
                return "Español";
            case ":pt":
                return "Português";
            case ":it":
                return "Italiano";
            case ":fr":
                return "Française";
        }
    }
    languageSelector.prototype.create = function () {
        var self = this;
        var t;
        this.inputs = [];
        var makeRadioButton = function (value, currentValue) {
            var opt;
            var rb = document.createElement("input");
            opt = self.langIdToString(value)
            rb.type = "radio";
            rb.id = "prisma_field_" + self.id + "_" + value;
            rb.name = "prisma_field_" + self.id;
            rb.checked = value == currentValue;

            // AriaLabel on radiobuttons options.
            rb.ariaLabel = opt;

            rb.setAttribute("value", value);
            self.inputs.push(rb);
            var lb = document.createElement("label");
            lb.className = "control-label";
            var spn = document.createElement("span");
            spn.setAttribute("for", "prisma_field_" + self.id + "_" + value);
            spn.innerHTML = opt;
            lb.appendChild(rb);
            lb.appendChild(spn);
            return lb;
        }
        var makeRadioButtons = function (languages) {
            var ul = document.createElement("ul");
            for (var i = 0; i < languages.length; i++) {
                var li = document.createElement("li");
                li.className = "radio";
                var opt = makeRadioButton(languages[i], self.value);
                li.appendChild(opt);
                ul.appendChild(li);
            }
            return ul;
        }
        switch (this.displayMode) {

            case ":combobox":
                var options = [];
                for (var i = 0; i < this.availableLanguages.length; i++) {
                    options.push(this.langIdToString(this.availableLanguages[i]))
                }
                this.combobox = new combobox(this.context, {
                    id: this.id,
                    storage_key: this.storage_key,
                    context_registration: false,
                    value: this.langIdToString(this.value),
                    label: this.label,
                    options: options,
                    required: this.required,
                    form_alignment: this.form_alignment
                }, []);
                return this.combobox.create();

            case ":vertical-radiobuttons":
                t = makeRadioButtons(this.availableLanguages);
                t.className = "list-unstyled prisma-radiobutton-component";
                break;
            case ":horizontal-radiobuttons":
                t = makeRadioButtons(this.availableLanguages);
                t.className = "list-unstyled list-inline prisma-radiobutton-component";
                break;


        }
        t.id = this.id;
        if(self.visible===false)
            self.setVisible(false);
        if(self.enabled===false)
            self.setEnabled(false);
        return this.form_layout(t);
    }
    languageSelector.prototype.retrieveData = function (cb) {
        var optsMap = {}
        for (var i = 0; i < this.availableLanguages.length; i++) {
            optsMap[this.langIdToString(this.availableLanguages[i])] = this.availableLanguages[i];
        }
        if (this.combobox) {
            this.combobox.retrieveData(function (status, value) {

                if (status) {
                    cb(status, unkeywordize(optsMap[value]));
                } else
                    cb(status)
            });
        } else {
            var value = null;
            for (var optindex = 0; optindex < this.inputs.length; optindex++) {
                var opt = this.inputs[optindex];
                if (opt.checked) {
                    value = opt.getAttribute("value");
                }
            }
            if (value == null && this.required && this.visible) {
                prisma.addClass(this.frmgroup, "has-error");
                this.error_indicator.set(this._T(":funnel-navigation", ":errors-option-required"));
                cb(false);
            } else {
                prisma.removeClass(this.frmgroup, "has-error");
                this.error_indicator.clear();
                cb(true, unkeywordize(value));
            }
        }

    }
    languageSelector.prototype.onChange = function fn(){
        this.addListener("onChange",fn)
    }

    errorBox.prototype.create = function () {
        var generalErrorWrapper = document.createElement("div");
        generalErrorWrapper.className = "prisma-recaptcha-component";
        var generalErrorFormGroup = document.createElement("div");
        generalErrorFormGroup.className = "form-group ";
        this.generalErrorFormGroup = generalErrorFormGroup;
        this.generalErrorMessage = document.createElement("div");
        this.generalErrorMessage.className = "error-top error-fixed"
        if (this.text && this.text !== "") {
            this.generalErrorFormGroup.className = "form-group has-error";
            this.generalErrorMessage.innerHTML = this.text;
        }
        generalErrorFormGroup.appendChild(this.generalErrorMessage);
        generalErrorWrapper.appendChild(generalErrorFormGroup);
        return generalErrorWrapper;
    }

    errorBox.prototype.setText = function (txt) {
        this.text = txt;
        this.generalErrorMessage.innerHTML = this.text;
        if (this.text && this.text !== "") {
            this.generalErrorFormGroup.className = "form-group has-error";
        } else {
            this.generalErrorFormGroup.className = "form-group";
        }
    }

    function errorBox(context, opts, childs) {
        errorBox.SUPERconstructor.call(this, context, opts, childs);
    }
    inherit(errorBox, widget)

    errorBox.prototype.create = function () {
        var generalErrorWrapper = document.createElement("div");
        generalErrorWrapper.className = "";
        var generalErrorFormGroup = document.createElement("div");
        generalErrorFormGroup.className = "form-group ";
        this.generalErrorFormGroup = generalErrorFormGroup;
        this.generalErrorMessage = document.createElement("div");
        this.generalErrorMessage.className = "error align-top";
        this.generalErrorMessageText = document.createElement("p");
        if (this.text && this.text !== "") {
            this.generalErrorFormGroup.className = "form-group has-error fixed";
            this.generalErrorMessageText.innerHTML = this.text;
        }
        this.generalErrorMessage.appendChild(this.generalErrorMessageText);
        generalErrorFormGroup.appendChild(this.generalErrorMessage);
        generalErrorWrapper.appendChild(generalErrorFormGroup);
        return generalErrorWrapper;
    }

    errorBox.prototype.setText = function (txt) {
        this.text = txt;
        this.generalErrorMessageText.innerHTML = this.text;
        if (this.text && this.text !== "") {
            this.generalErrorFormGroup.className = "form-group has-error fixed";
        } else {
            this.generalErrorFormGroup.className = "form-group";
        }
    }
    prisma.errorbox = errorBox;

    function datacapture(context, opts, childs) {
        var c = [];
        var self = this;
        this.inputs = [];
        this.errors = [];
        this.opts = opts;
        for (var i = 0; i < opts.fields.length; i++) {
            var field = opts.fields[i];
            var fieldName = field["field-name"]?field["field-name"].substr(1):field["name"];
            var value =  opts["fields-data"] && opts["fields-data"][fieldName]?(opts["fields-data"][fieldName] || field["value"]):field["value"];
            var input;

            switch (unkeywordize(field["control-type"])) {
                case "language-selector":
                    input = new languageSelector(context, {
                        id: opts.id + "-" + field["name"],
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        srctype: unkeywordize(field["multioption-source"]),
                        options: field["options"],
                        catalog: field["catalog"],
                        required: field["required"],
                        availableLanguages: field["available-languages"],
                        displayMode: field["display-mode"],
                        form_alignment: opts["form-alignment"]
                    }, []);
                    break;
                case "file-upload":
                    input = new binaryUpload(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        srctype: unkeywordize(field["multioption-source"]),
                        options: field["options"],
                        catalog: field["catalog"],
                        required: field["required"],
                        form_alignment: opts["form-alignment"]

                    }, []);
                    break;
                case "combobox":
                    input = new combobox(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        srctype: unkeywordize(field["multioption-source"]),
                        options: field["options"],
                        catalog: field["catalog"],
                        required: field["required"],
                        form_alignment: opts["form-alignment"]
                    }, []);
                    break;
                case "multi-option":
                    input = new multioption(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        srctype: unkeywordize(field["multioption-source"]),
                        options: field["options"],
                        catalog: field["catalog"],
                        required: field["required"],
                        form_alignment: opts["form-alignment"],
                        controlStyle: field["control-style"],
                    }, []);
                    break;
                case "multiple-choice":
                    input = new MultipleChoice(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        srctype: unkeywordize(field["multioption-source"]),
                        options: field["options"],
                        catalog: field["catalog"],
                        required: field["required"],
                        form_alignment: opts["form-alignment"]
                    }, []);
                    break;
                case "checkbox":
                    input = new checkbox(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        required: field["required"],
                        form_alignment: opts["form-alignment"]
                    }, []);
                    break;
                case "text":
                    input = new textinput(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        required: field["required"],
                        maxLength: field["max-length"],
                        form_alignment: opts["form-alignment"]
                    }, []);
                    break;
                case "email-input":
                    input = new textinput(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        required: field["required"],
                        maxLength: field["max-length"],
                        form_alignment: opts["form-alignment"]
                    }, []);

                    input.addListener("validate", function (value) {
                        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) || ((typeof value) == "string" && prisma.trim(value).length == 0)) {
                            return true;
                        } else {
                            return self._T(":funnel-navigation", ":errors-invalid-email")
                        }
                    });
                    break;

                case "password":
                    input = new textinput(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        required: field["required"],
                        maxLength: field["max-length"],
                        form_alignment: opts["form-alignment"],
                        mask: "password"
                    }, []);
                    break;

                case "paragraph":
                    input = new textinput(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        required: field["required"],
                        maxLength: field["max-length"],
                        form_alignment: opts["form-alignment"],
                        multiline: true
                    }, []);
                    break;
                case "date":
                    input = new textinput(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value instanceof Date  ? value :new Date(Date.parse(value)),
                        label: field["label"],
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        required: field["required"],
                        form_alignment: opts["form-alignment"],
                        mask: "date"
                    }, []);
                    break;
                case "integer":
                    input = new textinput(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        maxLength: field["max-length"],
                        label: field["label"],
                        mask: "numeric",
                        decimals: 0,
                        required: field["required"],
                        form_alignment: opts["form-alignment"],
                        allowNegative: true
                    }, []);
                    break;

                case "unint":
                    input = new textinput(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        mask: "numeric",
                        decimals: 0,
                        required: field["required"],
                        form_alignment: opts["form-alignment"],
                        allowNegative: false
                    }, []);
                    break;
                case "decimal":
                    input = new textinput(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        required: field["required"],
                        form_alignment: opts["form-alignment"],
                        mask: "numeric",
                        allowNegative: true,
                        decimals: 2,
                        decimalSeparator: "."
                    }, []);
                    break;
                case "currency":
                    input = new textinput(context, {
                        id: opts.id + "-" + field["name"],
                        field: field,
                        storage_key: field["name"],
                        context_registration: false,
                        visible: !field["hidden"],
                        enabled: !field["read-only"],
                        value: value,
                        scripts: field["scripts"],
                        "startup-scripts": field["startup-scripts"],
                        label: field["label"],
                        mask: "numeric",
                        required: field["required"],
                        form_alignment: opts["form-alignment"],
                        decimals: 2,
                        allowNegative: true,
                        decimalSeparator: ".",
                        currency: true,
                        thousandsSeparator: ","
                    }, []);
                    break;
            }
            input.onChange((function (field) {
                return function (value) {
                    self.context.view.handleFieldChange(field, value);
                    self.fireEvent("onChange" ,{field: field, value: value})
                }
            })(field));

            this.inputs.push({
                input: input,
                field: field
            });
            c.push(input);
        }
        if (opts["captcha-v2"]) {
            var input = new recaptcha_v2(context, {
                context_registration: false,
                "google-captcha-v2-site-key": opts["google-captcha-v2-site-key"],
                form_alignment: "vertical"
            }, []);
            c.push(input);
            this.inputs.push({
                input: input,
                field: {
                    name: "__captcha-v2__"
                }
            })
        }
        var generalError = new errorBox({}, {
            id: opts.id + "-general-error",
            text: ''
        }, []);
        c.push(generalError);
        datacapture.SUPERconstructor.call(this, context, opts, c);

        this.generalError = generalError;
    }
    inherit(datacapture, container);
    datacapture.prototype.setError = function (err) {
        this.errors = this.errors || [];
        if (err) {
            this.errors.push(err)
        } else
            this.errors = [];
    }
    datacapture.prototype.retrieveData = function (cb) {
        var self = this;
        self.errors = [];
        var r = new jsedn.Map();
        var has_errors = false;
        var ctrls = this.inputs.slice();
        var retrieve_next = function (c) {
            if (c) {
                if (c.input.retrieveData) {
                    c.input.retrieveData(function (status, data) {
                        if (status) {
                            r.set(c.field["name"], data)
                        } else {
                            has_errors = true;
                        }
                        retrieve_next(ctrls.shift());
                    })
                } else {
                    retrieve_next(ctrls.shift());
                }
            } else {

                self.generalError.setText(self.errors.join("<br>"));
                cb(!has_errors && self.errors.length == 0, r);
            }
        }
        retrieve_next(ctrls.shift());
    }
    datacapture.prototype.handleErrors = function (errors) {

        for (var i = 0; i < this.inputs.length; i++) {
            var c = this.inputs[i];
            if (c.input.displayError) {
                if (errors[this.inputs[i].field["name"]]) {
                    this.inputs[i].input.displayError([errors[this.inputs[i].field["name"]]]);
                } else {
                    this.inputs[i].input.displayError(null);
                }
            }
        }
        if (errors[":validations-errors"]) {
            for (var i = 0; i < errors[":validations-errors"].length; i++) {
                this.setError(errors[":validations-errors"][i])
            }
        } else {
            this.setError(null); //clear error indicator
        }
        this.generalError.setText(this.errors.join("<br>"));
    }

    datacapture.prototype.create = function () {
        var self = this;
        var n = datacapture.SUPERclass.create.call(this);
        n.className = "prisma-datacapture-component";
        if (this.opts["form-alignment"] !== "vertical"){
            n.className += " form-horizontal";
        }
        var s = (this.padding ? "padding: " + this.padding + ";" : "padding: 10px;");
        n.setAttribute("style", s);
        if(self.visible===false)
            self.setVisible(false);
        if(self.enabled===false)
            self.setEnabled(false);
        for (var i = 0 ; i< this.inputs.length;i++){
            var field = this.inputs[i].field;
            var fieldName = field["field-name"]?field["field-name"].substr(1):field["name"];
            var value =  this["fields-data"] && this["fields-data"][fieldName]?(this["fields-data"][fieldName] || field["value"]):field["value"];
            this.context.view.handleFieldChange(field, value);

        }

        return n;
    }

    prisma.datacapture = datacapture;

    function actions_bar(context, opts, childs) {
        var self = this;
        var c = [];
        context.actions_bars = context.actions_bars || [];
        context.actions_bars.push(this);
        for (var i = 0; i < opts.buttons.length; i++) {
            var v = opts.buttons[i];
            if (v.enabled) {
                (function (action) {
                    if (context.view.isActionVisible(action) &&
                        v.type != ":funnel-step-s12n-action-type/dismiss") {
                        var btn = new button(context, {
                            text: v.label || self.btn_text(v),
                            enabled: context.view.isActionEnabled(action),
                            horizontalMargin: opts.spacing,
                            className: context.view.getActionClass(action),
                            font: opts.font
                        }, []);
                        btn.onClick(function (event){
                            context.view.funnel.onAction(action,function (){});
                        });
                        v.btn = btn;
                        c.push(btn);
                    }
                })(new jsedn.Map([jsedn.kw(":funnel-step-s12n-action/type"), jsedn.kw(v.type)]));
            }
        }
        actions_bar.SUPERconstructor.call(this, context, opts, c);
    }
    inherit(actions_bar, container);
    actions_bar.prototype.btn_text = function (btn) {
        switch (unkeywordize(btn.type)) {
            case "funnel-step-s12n-action-type/dismiss":
                return "Dismiss";
            case "funnel-step-s12n-action-type/cancel":
                return "Cancel";
            case "funnel-step-s12n-action-type/previous":
                return "Previous"
            case "funnel-step-s12n-action-type/next":
                return "Next";
            default:
                return unkeywordize(btn.type);

        }
    }

    actions_bar.prototype.create = function () {
        var self = this;
        var n = actions_bar.SUPERclass.create.call(this);
        var s = (this.padding ? "padding: " + this.padding + ";" : "") +
            (this.border ? "border: " + this.border.width + " solid " + (this.border.color || "black") + ";" : "") +
            (this.background ? "background: " + this.background + ";" : "") +
            (this.align ? "text-align:" + this.align + ";" : "");
        n.setAttribute("style", s);
        n.className = "prisma-actions-component-content";
        var x = document.createElement("div");
        x.className = "prisma-actions-component";
        x.id = this.id;
        x.appendChild(n);
        for (var i = 0; i < this.buttons.length; i++) {
            var v = this.buttons[i];
            if (v.type == ":funnel-step-s12n-action-type/dismiss") {
                (function (action) {
                    if (self.context.view.isActionVisible(action)) {
                        var dismissElement = document.createElement("a");
                        dismissElement.className = "prisma-dismiss-component";
                        dismissElement.setAttribute("href", "#");
                        dismissElement.innerHTML = v.label || "No me interesa esta promoción";
                        prisma.setEvent(dismissElement, "click",
                            function (e) {
                                self.context.view.funnel.onAction(action,function(){});
                                e.preventDefault ? e.preventDefault() : (e.returnValue = false);
                            });
                        x.appendChild(dismissElement);
                    }
                })(new jsedn.Map([jsedn.kw(":funnel-step-s12n-action/type"), jsedn.kw(v.type)]));
            }
        }
        if(self.visible===false)
            self.setVisible(false);
        if(self.enabled===false)
            self.setEnabled(false);

        this.domNode = x;
        return x;
    }
    actions_bar.prototype.setEnabled = function (enabled) {
        for (var i = 0; i < this.buttons.length; i++) {
            this.buttons[i].setEnabled(enabled);
        }
    }

    prisma.actions_bar = actions_bar;


    function GliaCobrowsing(context, opts, children) {
        GliaCobrowsing.SUPERconstructor.call(this, context, opts, children);
    }

    prisma.inherit(GliaCobrowsing, prisma.widget);

    GliaCobrowsing.prototype.create = function() {
        var elementId = "glia-cobrowsing-component";
        var e = document.getElementById(elementId);
        if (e) {
            console.warn("GLia Cobrowsing component already loaded")
            return e;
        } else {
            console.log("Loading Glia Cobrowsing component");
            e = document.createElement('script');
            e.id = elementId;
            e.classList.add('glia-cobrowsing-component');
            e.setAttribute('type', 'text/javascript');
            e.setAttribute('src', 'https://api.glia.com/salemove_integration.js');
            e.setAttribute('async', 'true');

            var onElementRemovedDeferred;

            var onElementRemoved = function(evt) {
                var elem = evt.target;
                if (elem instanceof HTMLElement &&
                    elem.getElementsByClassName('glia-cobrowsing-component').length > 0) {
                    // do not handle the event immediately so we can check
                    // the element was actually removed
                    setTimeout(onElementRemovedDeferred, 200);
                }
            };

            onElementRemovedDeferred = function() {
                if (document.getElementById(elementId)) {
                    console.log("Ignoring spurious remove event")
                } else {
                    document.body.removeEventListener('DOMNodeRemoved', onElementRemoved);
                    console.log("Shutting down Glia integration")
                    document.getElementById('salemove').remove();
                    // this is the best we can do since we do not have control over glia's code
                    delete window.sm;
                }
            };

            e.addEventListener('load', function() {
                // note: MutationObserver doesn't seem to be working
                document.body.addEventListener('DOMNodeRemoved', onElementRemoved);
            });
            return e;
        }
    };

    prisma.glia_cobrowsing = GliaCobrowsing;

    function HtmlSnippet(context, opts, children) {
        HtmlSnippet.SUPERconstructor.call(this, context, opts, children);
    }

    prisma.inherit(HtmlSnippet, prisma.widget);

    HtmlSnippet.prototype.create = function() {
        const container = document.createElement("div");
        container.innerHTML = this['html-content'];
        this.enableScripts(container);
        return container;
    };

    // loads scripts embedded in the snippet
    HtmlSnippet.prototype.enableScripts = function(container) {
        Array.from(container.querySelectorAll("script"))
             .forEach( oldScriptEl => {
                 const newScriptEl = document.createElement("script");
                 Array.from(oldScriptEl.attributes).forEach( attr => {
                     newScriptEl.setAttribute(attr.name, attr.value)
                 });
                 const scriptText = document.createTextNode(oldScriptEl.innerHTML);
                 newScriptEl.appendChild(scriptText);
                 oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
             });
        this.setupEvents(container);
    }

    // handles action events on any control having the data-action attribute
    HtmlSnippet.prototype.setupEvents = function(container) {
        const self = this;
        const actionElements = container.querySelectorAll(
            '[data-action="dismiss"], [data-action="cancel"], ' +
            '[data-action="previous"], [data-action="next"]');
        actionElements.forEach(function(element) {
            prisma.setEvent(element, 'click', function(event) {
                const action = this.getAttribute('data-action');
                const actionMap= new jsedn.Map([jsedn.kw(":funnel-step-s12n-action/type"),
                                                jsedn.kw(":funnel-step-s12n-action-type/" + action)]);
                event.preventDefault();
                self.context.view.funnel.onAction(actionMap, () => {});
            });
        });
    }

    prisma.html_snippet = HtmlSnippet;

    function normalizeIdentifier(id) {
        if (id.length == 0) {
            throw ("Invalid identifier");
        }
        var newid = id.replace(/-/g, "_");
        if (newid.match(/^[\d].+$/)) {
            newid = "k" + newid;
        }
        return newid;
    }
    function fmatch (fname,name){
        return null !== fname.match(new RegExp ("/" + name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') +"$","gi"));
    }


    function Page(def, funnel) {
        this.controlStates = {};
        this.fieldchangeEvents = []
        this.fieldvalidateEvents = []
        this.fields_data = {}
        this.whendaemons = [];
        Page.SUPERconstructor.call(this, def, funnel);
    }
    prisma.registerPlugin(jsedn.kw(":built-in/page"), Page);

    Page.prototype.buildControl = function (context, control) {
        var r = []
        if (control.length > 2) {
            for (var i = 2; i < control.length; i++) {
                var pageControl = this.buildControl(context, control[i]);
                if (pageControl) {
                    r.push(pageControl);
                }
            }
        }
        var controlName;
        if (typeof(control[0]) == "string") {
            controlName = control[0].substr(1).replace(/-/g, "_");
            if (!prisma[controlName]) {
                return;
            }
        }
        else {
            return;
        }
        var c = new prisma[controlName](context, control[1], r);
        var ivs = (control[1].visible != undefined ? control[1].visible : true);
        var ies = (control[1].enabled != undefined ? control[1].enabled : true);
        this.controlStates[control[1].id] = {
            control: c,
            visible: {
                initial: ivs,
                current: ivs ? ivs : null
            },
            enabled: {
                initial: ies,
                current: ies ? ies : null
            }
        }
        return c;
    }

    Page.prototype.showControl = function (controlId) {
        if (this.controlStates[controlId]) {
            this.controlStates[controlId].visible.next = true;
        }
    }
    Page.prototype.hideControl = function (controlId) {
        if (this.controlStates[controlId]) {
            this.controlStates[controlId].visible.next = false;
        }
    }

    Page.prototype.enableControl = function (controlId) {
        if (this.controlStates[controlId]) {
            this.controlStates[controlId].enabled.next = true;
        }
    }
    Page.prototype.disableControl = function (controlId) {
        if (this.controlStates[controlId]) {
            this.controlStates[controlId].enabled.next = false;
        }
    }

    Page.prototype.clearStates = function () {
        for (c in this.controlStates) {
            if (this.controlStates.hasOwnProperty(c)) {
                this.controlStates[c].visible.next = null;
                this.controlStates[c].enabled.next = null;
            }
        }
    }
    Page.prototype.applyStates = function () {
        for (c in this.controlStates) {
            if (this.controlStates.hasOwnProperty(c)) {
                var st = this.controlStates[c];
                if ((st.visible.next !== null && st.visible.next !== undefined)) {
                    if (st.visible.next !== st.visible.current) {
                        st.control.setVisibility(st.visible.next);
                        st.visible.current = st.visible.next;
                    }
                }
                if ((st.enabled.next !== null && st.enabled.next !== undefined)) {
                    if (st.enabled.next !== st.enabled.current) {
                        st.control.setEnabled(st.enabled.next);
                        st.enabled.current = st.enabled.next;
                    }
                }

            }
        }
    }
    Page.prototype.enableActions = function (enabled) {
        for (var i = 0;
            (this.context.actions_bars || []).length; i++) {
            var actionBar = this.context.actions_bars[i];
            actionBar.setEnabled(enabled);
        }
        for (var i = 0;
            (this.context.action_buttons || []).length; i++) {

            var action_btn = this.context.action_buttons[i];
            action_btn.setEnabled(enabled);

        }
        return (this.context.actions_bars || []).length > 0 ||
            (this.context.action_buttons || []).length > 0;
    }

    Page.prototype.runScripts = function (done) {
        var self = this;
        this.root.runScripts("scripts",null,done);
    }
    Page.prototype.getControl = function (controlId) {
        for (var i = 0; i < this.context.controls.length; i++) {
            if (this.context.controls[i].id == controlId)
                return this.context.controls[i];
        }
    }
    Page.prototype.fireFieldValidateEvents = function (fieldId, resolve, reject, value) {
        var handlers = (this.fieldvalidateEvents[fieldId] || []).slice();
        var fireEvt = function (cbcall) {
            if (cbcall) {
                cbcall(
                    function (result) {
                        if (result && result !== true) {
                            reject(result)
                        } else {
                            fireEvt(handlers.shift())
                        }
                    },
                    value)
            } else {
                resolve()
            }
        }
        fireEvt(handlers.shift());
    }
    Page.prototype.fireFieldChangeEvents = function (fieldId, fieldValue,done) {
        var chgevents = (this.fieldchangeEvents[fieldId] || []).slice();
        var fireEvt = function (cbcall) {
            if (cbcall) {
                cbcall(function () {
                    fireEvt(chgevents.shift())
                },
                fieldValue)
            } else {
                done()
            }
        }
        fireEvt(chgevents.shift());
    }
    Page.prototype.processWhenDaemons = function (done){
        var self = this;
        var whens = self.whendaemons.slice();
        var doWhen = function (when){
            if(when){
                when.whenExpr(function(value){
                    if(value){
                        when.valueExpr(function(value){
                            if (when.type == "field") {
                                if(when.propertyName == "value"){
                                    var field = null;
                                    for (var i = 0; !field && i < self.context.controls.length; i++) {
                                        var c = self.context.controls[i];
                                        if (c["fields-data"] && c.inputs) {
                                            for (var j = 0; !field && j < c.inputs.length; j++) {
                                                var di = c.inputs[j];
                                                if (di.field["ns:name"] == when.fieldName){
                                                    di.input.setValue(value);
                                                }else if (di.field["name"] == when.fieldName) {
                                                    field = di.field
                                                    di.input.setValue(value);
                                                }
                                            }
                                        }
                                    }
                                    field = field || {"field-name": ":"+when.fieldName};
                                    self.setFieldValue(field,value);
                                }else {
                                    for (var i = 0; i < self.context.controls.length; i++) {
                                        if (self.context.controls[i]["fields-data"]) {
                                            if (self.context.controls[i].inputs) {
                                                for (var j = 0; j < self.context.controls[i].inputs.length; j++) {
                                                    var di = self.context.controls[i].inputs[j];
                                                    if (di.field["ns:name"] == when.fieldName) {
                                                        di.input.setVisible(value);
                                                    } else {
                                                        if ((di.field["field-name"] == when.fieldName ||
                                                            fmatch(di.field["field-name"], when.fieldName))) {
                                                            di.input.setVisible(value);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            doWhen(whens.shift());
                        })
                    }else
                        doWhen(whens.shift());
                })
            }else
                done();
        }
        doWhen(whens.shift());
    }
    Page.prototype.setFieldValue = function (field, newValue){
        this.fields_data[field["field-name"].substr(1)] = newValue;
        for (var i = 0; i < this.context.controls.length; i++) {
            if (this.context.controls[i]["fields-data"]) {
                var matched = false;
                for (var fname in this.context.controls[i]["fields-data"]) {
                    if (this.context.controls[i]["fields-data"].hasOwnProperty(fname)) {
                        if (fmatch(fname, field["field-name"].substr(1))) {
                            this.context.controls[i]["fields-data"][fname] = newValue;
                            matched = true;
                        }
                    }
                }
                if (!matched) {
                    this.context.controls[i]["fields-data"][field["field-name"].substr(1)] = newValue;
                }
                if (this.context.controls[i].inputs) {
                    for (var j = 0; j < this.context.controls[i].inputs.length; j++) {
                        var di = this.context.controls[i].inputs[j];
                        if ((di.field["field-name"] == field["field-name"] ||
                            di.field["ns:name"] == field["field-name"].substr(1)) && di.field !== field)
                            di.input.setValue(newValue);
                    }
                }
            }
        }
    }

    Page.prototype.handleFieldChange = function (field, newValue) {
        var self = this;
        if (field["field-name"]) {
            this.clearStates();
            self.setFieldValue(field, newValue);
            self.fireFieldChangeEvents(field["ns:name"] || field["name"],
                newValue,
                function () {
                    self.processWhenDaemons(function () {
                        self.runScripts(function () {
                            self.context.view.applyStates();
                        });
                    });
                }
            )
        }
    }

    Page.prototype.createContent = function (definition) {
        var self = this;
        var current_resolution;
        var current_definition;
        var current_context;
        var current_node;
        var body = document.createElement("div");
        var init_at_resolution = function () {

            var w = prisma.viewportWidth();

            var device = definition[":devices"][":any"];
            var resolutions = device[":resolutions"];
            var resolution;
            var def;

            if (w < 768) {
                if (resolutions[":small"]) {
                    resolution = "small"
                    def = resolutions[":small"]
                } else
                    if (resolutions[":medium"]) {
                        resolution = "medium";
                        def = resolutions[":medium"];
                    } else {
                        resolution = "default";
                        def = resolutions[":default"];
                    }
            } else
                if (w < 992) {
                    if (resolutions[":medium"]) {
                        resolution = "medium";
                        def = resolutions[":medium"];
                    } else {
                        resolution = "default";
                        def = resolutions[":default"];
                    }
                } else {
                    resolution = "default";
                    def = resolutions[":default"];
                }
            if (current_resolution !== resolution) {
                if (current_resolution) {
                    self.root.destroy();
                    body.removeChild(current_node);
                }
                var api = self.funnel.handler.api;
                current_resolution = resolution;
                current_context = current_context || {
                    api: api,
                    view: self
                };
                current_context.resolution = resolution;
                current_context.controls = [];
                self.context = current_context;
                self.root = self.buildControl(self.context, unkeywordize(def));
                current_node = self.root.create();
                for (c in self.controlStates) {
                    if (self.controlStates.hasOwnProperty(c)) {
                        var st = self.controlStates[c];
                        if (!st.visible.current)
                            st.control.setVisibility(false)

                        if(!st.enabled.current)
                            st.control.setEnabled (false);
                    }
                }

                self.root.runScripts("startup-scripts",

                    function (identifier, resolve) {
                        switch (identifier) {
                            case "field-on":
                                resolve(function (fieldName, event, cbcall) {
                                    if (event == "change") {
                                        self.fieldchangeEvents[fieldName] = self.fieldchangeEvents[fieldName] || []
                                        self.fieldchangeEvents[fieldName].push(cbcall);
                                    } else if (event == "validate") {
                                        self.fieldvalidateEvents[fieldName] = self.fieldvalidateEvents[fieldName] || []
                                        self.fieldvalidateEvents[fieldName].push(cbcall);
                                    }
                                })
                                break;
                            case "get-field":
                                resolve(function (fieldName, resolve) {
                                    var result = null;
                                    for (var i = 0; !result && i < self.context.controls.length; i++) {
                                        var c = self.context.controls[i];
                                        if (c["fields-data"]) {
                                            for (var fname in c["fields-data"]) {
                                                if (c["fields-data"].hasOwnProperty(fname)) {
                                                    if (fmatch(fname, fieldName)) {
                                                        result = c["fields-data"][fname]
                                                    }
                                                }
                                            }
                                            if(result == null)
                                                result = c["fields-data"][fieldName];
                                        }
                                    }
                                    if (result === undefined)
                                        result = null;
                                    resolve(result);
                                });
                                break;
                            case "toggle-field":
                                resolve(function (cmd, fieldName) {
                                    for (var i = 0; i < self.context.controls.length; i++) {
                                        var c = self.context.controls[i];
                                        if (c["fields-data"] && c.inputs) {
                                            for (var j = 0; j < c.inputs.length; j++) {
                                                var di = c.inputs[j];
                                                if (di.field["ns:name"]) {
                                                    if (di.field["ns:name"] == fieldName) {
                                                        di.input.setVisibility(cmd == ":show" ? true : false);
                                                    }

                                                } else {
                                                    if (di.field["name"] == fieldName ||
                                                        fmatch(fieldName, di.field["name"])) {
                                                        di.input.setVisibility(cmd == ":show" ? true : false);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                });
                                break;
                            case "set-field":
                                resolve(function (fieldName, newValue) {
                                    var field = null;
                                    for (var i = 0; !field && i < self.context.controls.length; i++) {
                                        var c = self.context.controls[i];
                                        if (c["fields-data"] && c.inputs) {
                                            for (var j = 0; !field && j < c.inputs.length; j++) {
                                                var di = c.inputs[j];
                                                if (di.field["name"] == fieldName) {
                                                    field = di.field
                                                    di.input.setValue(newValue);
                                                }
                                            }
                                        }
                                    }
                                    field = field || { "field-name": ":" + fieldName };
                                    self.context.view.handleFieldChange(field, newValue);
                                })
                                break;
                            case "set-when":
                                resolve(function (fieldName, propertyName, valueExpr, whenExpr) {
                                    self.whendaemons.push({
                                        type: "field",
                                        fieldName: fieldName,
                                        propertyName: propertyName,
                                        valueExpr: valueExpr,
                                        whenExpr: whenExpr
                                    });
                                })
                            default:
                                resolve(null);
                        }
                    },
                    function () {
                        self.clearStates();
                        self.processWhenDaemons(function () {
                            self.runScripts(function () {
                                self.applyStates();
                                body.appendChild(current_node);
                            });
                        })
                    })
            }
        }
        init_at_resolution();
        document.body.onresize = function () {
            init_at_resolution();
        }
        return body;
    };

    Page.prototype.retrieveData = function (cb) {
        var r = new jsedn.Map();
        r.set(jsedn.kw(":device"), jsedn.kw(":any"));
        r.set(jsedn.kw(":resolution"), jsedn.kw(":" + this.context.resolution));
        var contextFields = new jsedn.Map()

        for (var controlName in this.context.controls) {
            if (this.context.controls.hasOwnProperty(controlName)) {
                var ctrl = this.context.controls[controlName];
                if (ctrl['fields-data']) {
                    var fieldsData = ctrl['fields-data'];
                    for (var fieldName in fieldsData) {
                        if (fieldsData.hasOwnProperty(fieldName) && fieldsData[fieldName]!==undefined && fieldName.indexOf(":") == -1) {
                            contextFields.set(new jsedn.kw(":" + fieldName), fieldsData[fieldName]);
                        }
                    }
                }
            }
        }
        for (var fieldName in this.fields_data) {
            if (this.fields_data.hasOwnProperty(fieldName) && this.fields_data[fieldName]!==undefined  && fieldName.indexOf(":") == -1 ){
                contextFields.set(new jsedn.kw(":" + fieldName), this.fields_data[fieldName]);
            }
        }

        if(contextFields.keys.length != 0){
            r.set(new jsedn.kw(':context-fields'), contextFields);
        }

        var l = this.context.controls.slice();
        var has_errors = false;
        var retrieve_next = function (c) {
            if (c) {
                if (c.retrieveData && c.visible !== false && c.enabled !== false) {
                    c.retrieveData(function (status, d) {
                        if (status) {
                            r.set(c.id, d);
                        } else
                            has_errors = true;
                        retrieve_next(l.shift());
                    });
                } else {
                    retrieve_next(l.shift());
                }
            } else {
                cb(!has_errors, r);
            }
        }
        retrieve_next(l.shift());
    }
    Page.prototype.hasActionBarSupport = function () {
        if ((this.context.actions_bars || []).length > 0 ||
            (this.context.action_buttons || []).length > 0) {
            return true;
        }
        // check if there are action elements included in html snippets
        const actionElements = this.dom.querySelectorAll(
            '[data-action="dismiss"], [data-action="cancel"], ' +
            '[data-action="previous"], [data-action="next"]');
        return actionElements.length > 0;
    }
    Page.prototype.hasDismissActionSupport = function () {

        var action_buttons = (this.context.action_buttons || [])
        var dismiss_buttons = 0;
        for (var i = 0; i < action_buttons.length; i++) {
            if (action_buttons[i].action === ":funnel-step-s12n-action-type/dismiss")
                dismiss_buttons++;
        }
        return (this.context.actions_bars || []).length > 0 || dismiss_buttons > 0;
    }
    Page.prototype.handleStepErrors = function (errors) {
        Page.SUPERclass.handleStepErrors.call(this,errors);
        var l = this.context.controls.slice();
        for (var i = 0; i < this.context.controls.length; i++) {
            var c = this.context.controls[i];
            if (errors[c.id]) {
                c.handleErrors(errors[c.id])
            }
        }
    }
    prisma.Page = Page;
})();
